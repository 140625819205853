import {BrowserCacheLocation, Configuration, LogLevel, PublicClientApplication} from "@azure/msal-browser";

const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_CLIENT_ID,
		authority: `https://${process.env.REACT_APP_B2C_APP}.b2clogin.com/${process.env.REACT_APP_B2C_APP}.onmicrosoft.com/${process.env.REACT_APP_USER_FLOW}`,
		knownAuthorities: [`${process.env.REACT_APP_B2C_APP}.b2clogin.com`],
		redirectUri: process.env.REACT_APP_SECURITY,
    },
    cache: {
      cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
      storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        }
      }
    }
  };

export default msalConfig;