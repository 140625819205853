import React, { useState, useEffect, useCallback, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ToggleButton } from "primereact/togglebutton";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { ProductService } from "../../service/ProductService";
import { useStore } from "../../hooks-store/store";
import { Link } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

const CarrierData = (props) => {
  const [tmwupdated, settmwupdated] = useState(false);
  const [tabledata, SetTableData] = useState([]);
  const [state, dispatch] = useStore();
  const productService = new ProductService();
  const [showLoadLoc, setShowLoadLoc] = useState(null);
  const [LoadLoc, setLoadLoc] = useState(null);
  const [ShowDeliverLoc, setShowDeliverLoc] = useState(null);
  const [DeliverLoc, setDeliverLoc] = useState(null);
  const [ShowDoc, setShowDoc] = useState(null);
  const [Doc, setDoc] = useState(null);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [filterEnabled, setFilterEnabled] = useState(false);
  const [dialogcomms, setdialogcomms] = useState("");
  const [dialogtmwnum, setdialogtmwnum] = useState("");
  const myToast = useRef(null);
  const fullName = state.fullname;
  const FilterMatchMode = {
    STARTS_WITH : 'startsWith',
    CONTAINS : 'contains',
    NOT_CONTAINS : 'notContains',
    ENDS_WITH : 'endsWith',
  };
  const [filters, setFilters] = useState({
    CarrierName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    Comments: { value: null, matchMode: FilterMatchMode.STARTS_WITH }
  });

  useEffect(() => {
    setShowLoadLoc("none");
    setShowDeliverLoc("none");
    setShowDoc("none");
    fetchcpLandingReport();
    dispatch("SEARCHCP", null);
  }, []);

  const fetchcpLandingReport = useCallback(async () => {
    dispatch("ISLOADING", true);
    let mounted = true;
    try {
      const response = await productService.GetReportData(
        props.selectedStartDate,
        props.selectedEndDate,
        props.selectedPoNumber ? props.selectedPoNumber : "",
        props.selectedTMWNumber ? props.selectedTMWNumber : "",
        props.selectedBOLNumber ? props.selectedBOLNumber : "",
        props.selectedConfirmationFilter,
        props.selectedLoadFilter,
        props.selectedInvoiceFilter,
        props.selectedDAFilter,
        props.selectedRegionFilter,
        props.selectedByState,
        props.selectedCarrierFilter
      );

      if (!response.length > 0) {
        SetTableData([]);
        dispatch("ISLOADING", false);
        dispatch("SEARCHCP", null);
      }
      const data = await response;

      if (mounted) {
        if (data.length > 0) {
          SetTableData(data);
        }
        dispatch("SEARCHCP", null);
        dispatch("ISLOADING", false);
      }
    } catch (error) {
      SetTableData([]);
      dispatch("ISLOADING", false);
      dispatch("SEARCHCP", null);
    }

    return () => (mounted = false);
  }, [state.searchCP]);
  
  useEffect(() => {
    if (state.searchCP !== null) {
      fetchcpLandingReport();
    }
  }, [fetchcpLandingReport]);

  const renderDetails = (tabledata) => {
    const customFilteredData = tabledata.map((items) => {
      let properties = {
        ...items,
      };
      return properties;
    });
    return customFilteredData;
  };

  var GetFile = function(event, param1, param2, param3, param4){
    event.preventDefault();
    const fileExt = param1.split('.').pop() === "pdf" ? "application/pdf" : `image/${param1.split('.').pop()}`;
    const fileName = param4 === "Submitted" ? `Archive\\${param1}` : param1;
    productService.GetBlobFile(fileName, param2, param3)
    .then((response) => {
      if (response.status !== 200) {
        dispatch("ISLOADING", false);
        myToast.current.show({ severity: "error", summary: "File Load Error", detail: `Error returned with status: ${response.status}`, sticky: false });
      }
      else {
        const file = new Blob([response.data], {type: `${fileExt}`});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    });
  };

  var OpenBOLEntry = function (event, param) {
    dispatch("PONUM", param);
  };

  const dialogFuncMap = {
    displayBasic: setDisplayBasic
  };

  const onClick = (name, param1, param2) => {
    dialogFuncMap[`${name}`](true);
    setdialogtmwnum(param2);
    setdialogcomms(param1);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const renderFooter = (name) => {
    return (
      <div>
        <Button label="OK" icon="pi pi-check" onClick={() => onHide(name)} autoFocus />
      </div>
    );
  };

  var OpenPopup = function (event, param) {
    return (
      <Dialog header="Header" visible={displayBasic} style={{ width: "50vw" }} footer={renderFooter("displayBasic")} onHide={() => onHide("displayBasic")}>
        <p>{param}</p>
      </Dialog>
    );
  };

  const LoadConfirmationStatus = (rowData) => {
    if (rowData.LoadConfirmationStatus === "Offered") return <Tag severity="info">Offered</Tag>;
    if (rowData.LoadConfirmationStatus === "Accepted") return <Tag severity="success">Accepted</Tag>;
    if (rowData.LoadConfirmationStatus === "Declined") return <Tag severity="danger">Declined</Tag>;
  };

  function onSelection(e) {
    if (e.value[0]) {
      if (e.value[0].LoadStatus == "Edited") {
        settmwupdated(e.value);
      } else {
        myToast.current.show({
          severity: "error",
          summary: "Non Selectable Row",
          detail: "This row cannot be selected as no edits were made to it. Please select/approve changes for rows with Load Status as Edited",
          sticky: false,
        });
      }
    } else {
      settmwupdated(false);
    }
  }

  const save = (e) => {
    if (tmwupdated) {
      const selectedPOs = tmwupdated.map((x) => x.PONumber);
      const selectedTMWs = tmwupdated.map((x) => x.TMWNumber);
      productService.approveEditedLoad(selectedPOs,selectedTMWs, fullName).then((response) => {
        if (response === "Approved") {
          fetchcpLandingReport();
          settmwupdated(null);
          myToast.current.show({ severity: "info", summary: "Saved successfully", detail: "Edits approved for POs " + selectedPOs, sticky: false });
        }
      });
    } else {
      myToast.current.show({ severity: "error", summary: "Rows not selected", detail: "Please select at least one row to Save edits", sticky: false });
    }
  };

  const exportcpuserstats = (e) => {
  
      productService.exportCPUserStatistics(props.selectedStartDate,props.selectedEndDate);
    
  };

  const exportcpusereffrep = (e) => {
  
    productService.exportCPUserEfficiencyRep(props.selectedStartDate,props.selectedEndDate);
  
};

  const PONumber = (rowData) => {
    return rowData.PONumber;
  };

  const TMWNumber = (rowData) => {
    return rowData.TMWNumber;
  };
  const DispatchArea = (rowData) => {
    return rowData.DispatchArea;
  };
  const Region = (rowData) => {
    return rowData.Region;
  };
  const CarrierName = (rowData) => {
    return rowData.CarrierName;
  };
  const BOL = (rowData) => {
    return rowData.BOL;
  };
  const RequestedLoadDate = (rowData) => {
    //return rowData.ActualLoadDate == "1900-01-01" ? "" : rowData.ActualLoadDate;
    return (rowData.RequestedLoadDate != rowData.ActualLoadDate && rowData.LoadStatus=="Edited")?  <label style={{ color: "red"}}>{rowData.RequestedLoadDate }</label>:rowData.RequestedLoadDate;
  };
  const ActualLoadDate = (rowData) => {
   // return rowData.ActualLoadDate == "1900-01-01" ? "" : rowData.ActualLoadDate;
   return ( rowData.RequestedLoadDate != rowData.ActualLoadDate && rowData.LoadStatus=="Edited")?  <label style={{ color: "red"}}>{rowData.ActualLoadDate }</label>:rowData.ActualLoadDate=="1900-01-01"?"":rowData.ActualLoadDate;
  };
  
  const RequestedDeliveryDate = (rowData) => {
    //return rowData.RequestedDeliveryDate;
    return (rowData.RequestedDeliveryDate != rowData.ActualDeliveryDate && rowData.LoadStatus=="Edited")?  <label style={{ color: "red"}}>{rowData.RequestedDeliveryDate }</label>:rowData.RequestedDeliveryDate;
  };
  const ActualDeliveryDate = (rowData) => {
   // return rowData.ActualDeliveryDate == "1900-01-01" ? "" : rowData.ActualDeliveryDate;
   return ( rowData.RequestedDeliveryDate != rowData.ActualDeliveryDate && rowData.LoadStatus=="Edited")?  <label style={{ color: "red"}}>{rowData.ActualDeliveryDate }</label>:rowData.ActualDeliveryDate=="1900-01-01"?"":rowData.ActualDeliveryDate;
  };
  
  const LoadLocationName = (rowData) => {
    return rowData.LoadLocationName;
  };
  const LoadLocationAddress = (rowData) => {
    return rowData.LoadLocationAddress;
  };
  const LoadLocationCity = (rowData) => {
    return rowData.LoadLocationCity;
  };
  const LoadLocationState = (rowData) => {
    return rowData.LoadLocationState;
  };
  const LoadLocationZip = (rowData) => {
    return rowData.LoadLocationZip;
  };

  const DeliveryLocationName = (rowData) => {
    return rowData.DeliveryLocationName;
  };
  const DeliveryLocationAddress = (rowData) => {
    return rowData.DeliveryLocationAddress;
  };
  const DeliveryLocationCity = (rowData) => {
    return rowData.DeliveryLocationCity;
  };
  const DeliveryLocationState = (rowData) => {
    return rowData.DeliveryLocationState;
  };
  const DeliveryLocationZip = (rowData) => {
    return rowData.DeliveryLocationZip;
  };

  const Quantity = (rowData) => {
    return <NumericFormat value={rowData.Quantity} displayType={"text"} thousandSeparator="," />;
  };
  const UOM = (rowData) => {
    return rowData.UOM;
  };
  const Product = (rowData) => {
    return rowData.Product;
  };
  const Comments = (rowData) => {
    return (
      <Link to="" onClick={() => onClick("displayBasic", rowData.Comments, rowData.TMWNumber)}>
        {rowData.Comments}
      </Link>
    );
  };
  const BOLFile = (rowData) => {
    if (rowData.FileInfo) {
      return JSON.parse(rowData.FileInfo).map((item, i) => (
        <a key={i} onClick={event => GetFile(event, item.FileName, item.FileType, rowData.PONumber, item.FileStatus)} href="#" target="_blank" rel="noopener,noreferrer">
          {item.FileType.toLowerCase() === "bol" ? item.FileName : ""}{" "}
        </a>
      ));
    }
  };

  const WayBillFile = (rowData) => {
    if (rowData.FileInfo) {
      return JSON.parse(rowData.FileInfo).map((item, i) => (
        <a key={i} onClick={event => GetFile(event, item.FileName, item.FileType, rowData.PONumber, item.FileStatus)} href="#" target="_blank" rel="noopener,noreferrer">
          {item.FileType.toLowerCase() === "waybill" ? item.FileName : ""}{" "}
        </a>
      ));
    }
  };

  const InvoiceFile = (rowData) => {
    if (rowData.FileInfo) {
      return JSON.parse(rowData.FileInfo).map((item, i) => (
        <a key={i} onClick={event => GetFile(event, item.FileName, item.FileType, rowData.PONumber, item.FileStatus)} href="#" target="_blank" rel="noopener,noreferrer">
          {item.FileType.toLowerCase() === "invoice" ? item.FileName : ""}{" "}
        </a>
      ));
    }
  };

  const AddDocs = (rowData) => {
    if (rowData.FileInfo) {
      return JSON.parse(rowData.FileInfo).map((item, i) => (
        <a key={i} onClick={event => GetFile(event, item.FileName, item.FileType, rowData.PONumber, item.FileStatus)} href="#" target="_blank" rel="noopener,noreferrer">
          {item.FileType.toLowerCase() === "docs" ? item.FileName : ""}{" "}
        </a>
      ));
    }
  };

  const LoadStatus = (rowData) => {
    if (rowData.LoadStatus === "In Process") return <label style={{ color: "blue" }}>In Process</label>;
    if (rowData.LoadStatus === "Edited") return <label style={{ color: "orange" }}>Edited</label>;
    if (rowData.LoadStatus === "Complete") return <label style={{ color: "green" }}>Complete</label>;
    if (rowData.LoadStatus === "Change Approved") return <label style={{ color: "green" }}>Approved</label>;
    if (rowData.LoadStatus === "Declined") return <label style={{ color: "red" }}>Declined</label>;
    if (rowData.LoadStatus === "Not Started") return <label>Not Started</label>;
  };

  const InvoiceStatus = (rowData) => {
    if (rowData.InvoiceStatus === "In Process") return <label style={{ color: "blue" }}>In Process</label>;
    if (rowData.InvoiceStatus === "Submitted") return <label style={{ color: "green" }}>Submitted</label>;
    if (rowData.InvoiceStatus === "Declined") return <label style={{ color: "red" }}>Declined</label>;
    if (rowData.InvoiceStatus === "Not Started") return <label>Not Started</label>;
  };

  const handleLoadLocationColumn = (e) => {
    if (e.value) {
      setShowLoadLoc(null);
    } else {
      setShowLoadLoc("none");
    }
    setLoadLoc(e.value);
  };

  const handleDeliveryLocationColumn = (e) => {
    if (e.value) {
      setShowDeliverLoc(null);
    } else {
      setShowDeliverLoc("none");
    }
    setDeliverLoc(e.value);
  };

  const handleDocumentsColumn = (e) => {
    if (e.value) {
      setShowDoc(null);
    } else {
      setShowDoc("none");
    }
    setDoc(e.value);
  };

  const isSelectable = (value, field) => {
    let selectable = true;
    switch (field) {
      case "LoadStatus":
        selectable = value.toLowerCase() === "edited";
      default:
        break;
    }
    return selectable;
  };


  const rowClassName = (data) => {
    return {
        'simple-highlight': isSelectable(data.LoadStatus, 'LoadStatus')
    }
  }

  let headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Carrier Details" colSpan={8} className="center-aligned-columns" />
        <Column header="Load Details" colSpan={8} className="center-aligned-columns" />
        <Column className="top-column-header warnings sub-column-header" header="Status" colSpan={2} />
        <Column header="Load Location" colSpan={5} style={{ display: showLoadLoc }} className="center-aligned-columns" />
        <Column header="Delivery Location" colSpan={5} style={{ display: ShowDeliverLoc }} className="center-aligned-columns" />
        <Column className="top-column-header warnings sub-column-header" header="Documents" colSpan={4} style={{ display: ShowDoc }} />
      </Row>
      <Row>
        <Column header="Change Approved" field="Edits" sortable style={{ width: "Auto" }} />
        <Column header="Region" field="Region" sortable style={{ width: "Auto" }} />
        <Column header="Dispatch Area" field="DispatchArea" sortable style={{ width: "Auto" }} />
        <Column header="Load Confirmation Status" field="LoadConfirmationStatus" sortable body={LoadConfirmationStatus} style={{ width: "Auto" }} />
        <Column header="PO#" field="PONumber" sortable body={PONumber} className="center-align-column" />
        <Column header="TMW#" field="TMWNumber" sortable body={TMWNumber} style={{ width: "Auto" }} />
        <Column header="Carrier" field="CarrierName" filter={filterEnabled} filterPlaceholder="Search" filterHeaderStyle={{width:'5%'}} style={{width:'20%'}} sortable />
        <Column header="BOL#" field="BOL" sortable body={BOL} style={{ width: "Auto" }} />
        <Column header="Requested Load Date" field="RequestedLoadDate" sortable body={RequestedLoadDate} style={{ width: "Auto" }} />
        <Column header="Actual Load Date" field="ActualLoadDate" sortable body={ActualLoadDate} style={{ width: "Auto" }} />
        <Column header="Requested Delivery Date" field="RequestedDeliveryDate" sortable body={RequestedDeliveryDate} style={{ width: "Auto" }} />
        <Column header="Actual Delivery Date" field="ActualDeliveryDate" sortable body={ActualDeliveryDate} style={{ width: "Auto" }} />

        <Column header="Quantity" field="Quantity" sortable className="right-align-column" style={{ width: "Auto" }} />
        <Column header="UOM" style={{ width: "Auto" }} />
        <Column header="Product" style={{ width: "Auto" }} />

        <Column header="Comments" field="Comments" filter={filterEnabled} filterPlaceholder="Search" style={{ width: "Auto" }} sortable />
        <Column header="Load Status" field="LoadStatus" sortable style={{ width: "Auto" }} />
        <Column header="Invoice Status" field="InvoiceStatus" sortable style={{ width: "Auto" }} />

        <Column header="Load Location Name" style={{ display: showLoadLoc, width: "Auto" }} />
        <Column header="Load Location Address" style={{ display: showLoadLoc, width: "Auto" }} />
        <Column header="Load Location City" style={{ display: showLoadLoc, width: "Auto" }} />
        <Column header="Load Location State" style={{ display: showLoadLoc, width: "Auto" }} />
        <Column header="Load Location Zip" style={{ display: showLoadLoc, width: "Auto" }} />

        <Column header="Delivery Location Name" style={{ display: ShowDeliverLoc, width: "Auto" }} />
        <Column header="Delivery Location Address" style={{ display: ShowDeliverLoc, width: "Auto" }} />
        <Column header="Delivery Location City" style={{ display: ShowDeliverLoc, width: "Auto" }} />
        <Column header="Delivery Location State" style={{ display: ShowDeliverLoc, width: "Auto" }} />
        <Column header="Delivery Location Zip" style={{ display: ShowDeliverLoc, width: "Auto" }} />

        <Column header="BOL" style={{ display: ShowDoc, width: "Auto" }} />
        <Column header="WayBill" style={{ display: ShowDoc, width: "Auto" }} />
        <Column header="Invoice" style={{ display: ShowDoc, width: "Auto" }} />
        <Column header="Add. Doc" style={{ display: ShowDoc, width: "Auto" }} />
      </Row>
    </ColumnGroup>
  );

  return (
    <React.Fragment>
      <Toast ref={myToast} />
      <div className="p-ai-center expand-collapse-buttons rail-hub-expand-columns p-d-flex p-flex-nowrap ">
        <Button
          icon="pi pi-filter"
          onClick={() => filterEnabled ? setFilterEnabled(false) : setFilterEnabled(true)}
        />
        <div style={{marginLeft: 'auto', marginRight: '0px'}}>
          <span className="p-mr-2">Expand/Collapse:</span>
          <ToggleButton
            className="p-button-sm p-mr-2"
            onLabel="Load Location"
            offLabel="Load Location"
            onIcon="pi pi-check"
            offIcon="pi pi-plus"
            checked={LoadLoc}
            onChange={handleLoadLocationColumn}
          />
          <ToggleButton
            className="p-button-sm p-mr-2"
            onLabel="Delivery Location"
            offLabel="Delivery Location"
            onIcon="pi pi-check"
            offIcon="pi pi-plus"
            checked={DeliverLoc}
            onChange={handleDeliveryLocationColumn}
          />
          <ToggleButton
            className="p-button-sm p-mr-2"
            onLabel="Documents"
            offLabel="Documents"
            onIcon="pi pi-check"
            offIcon="pi pi-plus"
            checked={Doc}
            onChange={handleDocumentsColumn}
          />
          <Button className="p-button-sm" label="Save" onClick={save} />
          <Button className="p-button-sm" style={{marginLeft: '5px'}} label="User stats" onClick={exportcpuserstats} />
          <Button className="p-button-sm" style={{marginLeft: '5px'}} label="Efficiency Report" onClick={exportcpusereffrep} />
        </div>
      </div>

      <div className="table-container table-responsive custom-no-display-table">
        <DataTable
          className="p-datatable-sm"
          value={renderDetails(tabledata)}
          paginator
          headerColumnGroup={headerGroup}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={20}
          rowsPerPageOptions={[10, 20, 50]}
          removableSort
          stripedRows
          selection={tmwupdated}
          selectionMode="checkbox"
          onSelectionChange={onSelection}
          rowClassName={rowClassName}
          responsiveLayout="scroll"
          scrollDirection="both"
          sortable
          scrollable
          scrollHeight="390px"
          dataKey="LoadID"
          filters={filters}
          filterDisplay="row"
        >
          <Column selectionMode="multiple" style={{ width: "5%" }} />
          <Column field="Region" body={Region} style={{ width: "5%" }} />
          <Column field="Dispatch Area" body={DispatchArea} style={{ width: "5%" }} sortable />
          <Column field="LoadConfirmationStatus" body={LoadConfirmationStatus} style={{ width: "10%" }} />
          <Column field="PO"  body={PONumber} style={{ width: "5%" }} />
          <Column field="TMWNumber"  body={TMWNumber} style={{ width: "5%" }} />
          <Column field="CarrierName" filter filterPlaceholder="Search" style={{ width: "10%" }} />
          <Column field="BOL"  body={BOL} style={{ width: "5%" }} />
          <Column field="RequestedLoadDate" body={RequestedLoadDate} style={{ width: "6%" }} />
          <Column field="ActualLoadDate" body={ActualLoadDate} style={{ width: "6%" }} />
          <Column field="RequestedDeliveryDate" body={RequestedDeliveryDate} style={{ width: "6%" }} />
          <Column field="ActualDeliveryDate" body={ActualDeliveryDate} style={{ width: "6%" }} />

          <Column field="Quantity" body={Quantity} className="right-align-column" style={{ width: "5%" }} />
          <Column field="UOM" body={UOM} style={{ width: "4%" }} />
          <Column field="Product" body={Product} style={{ width: "5%" }} />

          <Column field="Comments" body={Comments} style={{ width: "6%" }} />
          <Column field="LoadStatus"  body={LoadStatus} style={{ width: "6%" }} />
          <Column field="InvoiceStatus"  body={InvoiceStatus} style={{ width: "100px" }} />

          <Column field="LoadLocationName" body={LoadLocationName} style={{ display: showLoadLoc, width: "150px" }} />
          <Column field="LoadLocationAddress" body={LoadLocationAddress} style={{ display: showLoadLoc, width: "150px" }} />
          <Column field="LoadLocationCity" body={LoadLocationCity} style={{ display: showLoadLoc, width: "150px" }} />
          <Column field="LoadLocationState" body={LoadLocationState} style={{ display: showLoadLoc, width: "150px" }} />
          <Column field="LoadLocationZip" body={LoadLocationZip} style={{ display: showLoadLoc, width: "150px" }} />

          <Column field="DeliveryLocationName" body={DeliveryLocationName} style={{ display: ShowDeliverLoc, width: "150px" }} />
          <Column field="DeliveryLocationAddress" body={DeliveryLocationAddress} style={{ display: ShowDeliverLoc, width: "150px" }} />
          <Column field="DeliveryLocationCity" body={DeliveryLocationCity} style={{ display: ShowDeliverLoc, width: "150px" }} />
          <Column field="DeliveryLocationState" body={DeliveryLocationState} style={{ display: ShowDeliverLoc, width: "150px" }} />
          <Column field="DeliveryLocationZip" body={DeliveryLocationZip} style={{ display: ShowDeliverLoc, width: "150px" }} />

          <Column field="BOLFiles" body={BOLFile} style={{ display: ShowDoc, width: "150px" }} />
          <Column field="WayBillFile" body={WayBillFile} style={{ display: ShowDoc, width: "150px" }} />
          <Column field="InvoiceFile" body={InvoiceFile} style={{ display: ShowDoc, width: "150px" }} />
          <Column field="AddDoc" body={AddDocs} style={{ display: ShowDoc, width: "150px" }} />
        </DataTable>
        <Dialog
          header={`Comments for TMW number ${dialogtmwnum}`}
          visible={displayBasic}
          style={{ width: "50vw" }}
          footer={renderFooter("displayBasic")}
          onHide={() => onHide("displayBasic")}
        >
          <p>{dialogcomms}</p>
        </Dialog>
      </div>
    </React.Fragment>
  );
};

export default CarrierData;
