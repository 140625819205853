import { initStore } from "./store";

const configureStore = () => {
    const actions = {
        CPVIEW: (state, serverResp) => ({ cpView: serverResp }),
        SEARCHCP: (state, serverResp) => ({ searchCP: serverResp }),
        LOGISTICSTEAM: (state, serverResp) => ({ logisticsTeam: serverResp }),
        SUPPORTTEAM: (state, serverResp) => ({ supportTeam: serverResp }),
        READONLY: (state, serverResp) => ({ readOnly: serverResp }),
        DELIVERLOC: (state, serverResp) => ({ deliverloc: serverResp }),
        LOADLOC: (state, serverResp) => ({ loadloc: serverResp }),
    };

    initStore(actions, {
        cpView: true,
        searchCP: null,
        logisticsTeam: null,
        supportTeam: null,
        readOnly: null,
        deliverloc: null,
        loadloc: null
    });
};

export default configureStore;
