import { useMsal } from "@azure/msal-react";
import Error from "./Error";

export default PasswordReset => {
    const { instance } = useMsal();
    // debugger;
    if (PasswordReset.error.name === "ServerError"){
        if (PasswordReset.error.errorMessage.includes("AADB2C90118")) { // The user has forgotten their password.
            const authority = `https://${process.env.REACT_APP_B2C_APP}.b2clogin.com/${process.env.REACT_APP_B2C_APP}.onmicrosoft.com/${process.env.REACT_APP_USER_PR_FLOW}`;
            instance.loginRedirect({authority: authority})
            return null;
        }
        else {
            return Error;
        }
    }
}