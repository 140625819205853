import React, { useState } from "react";
import Filters from "./Filters";
import Table from "./Table";

const Report = () => {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedPoNumber, setSeletedPoNumber] = useState("");
  const [selectedTMWNumber, setSeletedTMWNumber] = useState("");
  const [selectedBOLNumber, setSeletedBOLNumber] = useState("");
  const [selectedCarrierFilter, setSelectedCarrierFilter] = useState([]);
  const [selectedConfirmationFilter, setSelectedConfirmationFilter] = useState([]);
  const [selectedLoadFilter, setSelectedLoadFilter] = useState([]);
  const [selectedInvoiceFilter, setSelectedInvoiceFilter] = useState([]);
  const [selectedRegionFilter, setSelectedRegionFilter] = useState([]);
  const [selectedDAFilter, setSelectedDAFilter] = useState([]);
  const [selectedStateFilter, setByState] = useState([]);

  const handleStartDate = (e) => {
    setSelectedStartDate(e);
  };
  const handleEndDate = (e) => {
    setSelectedEndDate(e);
  };
  const handlePoNumber = (e) => {
    setSeletedPoNumber(e);
  };
  const handleTMWNumber = (e) => {
    setSeletedTMWNumber(e);
  };
  const handleBOLNumber = (e) => {
    setSeletedBOLNumber(e);
  };
  const handleConfirmationStatusFilters = (e) => {
    setSelectedConfirmationFilter(e);
  };
  const handleLoadStatusFilters = (e) => {
    setSelectedLoadFilter(e);
  };
  const handleInvoiceStatusFilters = (e) => {
    setSelectedInvoiceFilter(e);
  };
  const handleDAFilters = (e) => {
    setSelectedDAFilter(e);
  };
  const handleRegionFilters = (e) => {
    setSelectedRegionFilter(e);
  };
  const handleCarrierFilters = (e) => {
    setSelectedCarrierFilter(e);
  };
  const handleByStateFilters = (e) => {
    setByState(e);
  };

  return (
    <div>
      <h4 className="p-p-3 main-title">Carrier Load Information Portal</h4>
      <Filters
        handleStartDate={handleStartDate}
        handleEndDate={handleEndDate}
        handlePoNumber={handlePoNumber}
        handleTMWNumber={handleTMWNumber}
        handleBOLNumber={handleBOLNumber}
        handleConfirmationStatusFilters={handleConfirmationStatusFilters}
        handleLoadStatusFilters={handleLoadStatusFilters}
        handleInvoiceStatusFilters={handleInvoiceStatusFilters}
        handleDAFilters={handleDAFilters}
        handleRegionFilters={handleRegionFilters}
        handleByStateFilters={handleByStateFilters}
        handleCarrierFilters={handleCarrierFilters}
        
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        selectedPoNumber={selectedPoNumber}
        selectedTMWNumber={selectedTMWNumber}
        selectedBOLNumber={selectedBOLNumber}
        selectedConfirmationFilter={selectedConfirmationFilter}
        selectedLoadFilter={selectedLoadFilter}
        selectedInvoiceFilter={selectedInvoiceFilter}        
        selectedDAFilter={selectedDAFilter}
        selectedRegionFilter={selectedRegionFilter}
        selectedByState={selectedStateFilter}
        selectedCarrierFilter={selectedCarrierFilter}
      />
      <Table
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        selectedPoNumber={selectedPoNumber}
        selectedTMWNumber={selectedTMWNumber}
        selectedBOLNumber={selectedBOLNumber}
        selectedConfirmationFilter={selectedConfirmationFilter}
        selectedLoadFilter={selectedLoadFilter}
        selectedInvoiceFilter={selectedInvoiceFilter}        
        selectedDAFilter={selectedDAFilter}
        selectedRegionFilter={selectedRegionFilter}
        selectedByState={selectedStateFilter}
        selectedCarrierFilter={selectedCarrierFilter}
      />
    </div>
  );
};

export default Report;
