import React, { useState, useRef, useCallback, useEffect, createRef } from "react";
import "../../styles/_main.scss";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Calendar } from "primereact/calendar";
import { useStore, dispatch } from "../../hooks-store/store";
import { ProductService } from "../../service/ProductService";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { FileUpload } from "primereact/fileupload";
import moment from "moment";
import { Toast } from "primereact/toast";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Card } from "primereact/card";
import { Fieldset } from 'primereact/fieldset';


const DeliveredLoadInputFields = (props) => {
  const [state, dispatch] = useStore();
  const [bol, setbol] = useState("");
  const [boldate, setboldate] = useState("");
  const [qty, setqty] = useState(0);
  const [shipperoptions, setShipperOptions] = useState([]);
  const [shipperName, setShipperName] = useState(null);
  const [bolfiledisplay, setbolfiledisplay] = useState("");
  var [docs, setdocs] = useState(null);
  const myToast = useRef(null);
  const fileTypeRegex = /^.*\.(tif|TIF|pjp|PJP|jfif|JFIF|ico|ICO|tiff|TIFF|gif|GIF|svg|SVG|xbm|XBM|jxl|JXL|jpeg|JPEG|svgz|SVGZ|jpg|JPG|webp|WEBP|png|PNG|bmp|BMP|pjpeg|PJPEG|avif|AVIF|pdf|PDF)$/;
  const bolRegex = /^[A-Za-z0-9-/_]*$/;
  const fileTypeRef = createRef();
  const productService = new ProductService();
  const jsonPayload = {
    BOL: bol,
    BOLDate: boldate ? new Date(boldate).toLocaleDateString("en-US") : "",
    Quantity: qty,
    Shipper: shipperName,
    ProcessedBy: state.email,
  };

  useEffect(() => {
    let mounted = true;
    productService.GetShipperInformation().then((data) => {
      if (mounted) {
        setShipperOptions(
          data[0].ShipperInformation.map((item) => ({
            name: item.ShipperCode,
            key: item.ShipperName,
          }))
        );

      }
      return () => (mounted = false);
    });
  },[]);

  const confirm = (e, funcName) => {
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      accept: () => funcName(e),
    });
  };

  const onShipperChange = (e) => {
    if(e.value.name)
    {
    setShipperName(e.value.name);
    }
    else
    {
      setShipperName(null);
    }
  };

  function clearDocs(e) {
    setdocs(null);
  }

  function clearAlls() {
    setdocs(null);
    setbol("");
    setboldate("");
    setqty(0);
    setShipperName("");
    setbolfiledisplay("");
  }

  var fd = new FormData();

  const onBasicUploadAuto = async (e) => {
    try {
      if (e.files != null)
      {
        setdocs(e.files[0]);
      } 
      else 
      {
        if (bol === "" || bol === null) 
        {
          myToast.current.show({ severity: "error", summary: "BOL cannot be empty", detail: "BOL is a mandatory field", sticky: false });
        } 
        else if (!bolRegex.test(bol)) 
        {
          myToast.current.show({ severity: "error", summary: "Invalid BOL", detail: "BOL cannot contain special characters except - / _", sticky: false });
        } 
        else if (boldate ==="" || boldate === null) 
        {
        myToast.current.show({ severity: "error", summary: "BOL Date cannot be empty", detail: "BOL date is a mandatory field", sticky: false });
        } 
        else if (new Date(moment(boldate).format("MM/DD/YYYY")) > new Date(moment().format("MM/DD/YYYY"))) 
        {
          myToast.current.show({ severity: "error", summary: "Invalid BOL Date", detail: "BOL Date cannot be a future Date!", sticky: false });
        }  
        else if (qty <= 0) 
        {
          myToast.current.show({ severity: "error", summary: "Quantity cannot be zero or negative!", detail: "Quantity cannot be zero or negative!", sticky: false });       
        }  
        else if (shipperName ==="" || shipperName === null) 
        {
          myToast.current.show({ severity: "error", summary: "Please select shipper!", detail: "Please select shipper!", sticky: false });
        }
       
        else if (docs ==="" || docs === null) 
        {
          myToast.current.show({ severity: "error", summary: "Please select a file to upload!", detail: "Please select a file to upload!", sticky: false });
        }
        else 
        {
         
        
          if (docs) 
          {
            if (fileTypeRegex.test(docs.name)) 
            {
              fd.append("docs", docs);
            } 
            else 
            {
              throw "Please upload an image or a pdf file for document";
            }
          }
          fd.append("Payload", JSON.stringify(jsonPayload));
          dispatch("ISLOADING", true);
          fileTypeRef.current.clear();
          productService.postDeliveredLoads(fd).then((resp) => {
            if (resp == "200") 
            {
              dispatch("ISLOADING", false);
              myToast.current.show({ severity: "info", summary: "Saved Successfully", detail: "Saved Successfully", sticky: false });
              clearAlls();
            } 
            else 
            {
              
              dispatch("ISLOADING", false);
              myToast.current.show({ severity: "error", summary: "Save Error", detail: `Error returned with status: ${resp}`, sticky: false });
            }

          })
          
        }
      }
    } 
    catch (ex) 
    {
      dispatch("ISLOADING", false);
      myToast.current.show({ severity: "error", summary: "Save Error", detail: `Error while Saving: ${ex}`, sticky: false });
    }
  };


  return (
    <>
      <Toast ref={myToast} />
      <h4 className="p-p-3 main-title">Capture Delivered Loads</h4>
      <Card>
        <div className="p-grid ">
          <div className="col-4"></div>
          <div className="col-4">
            <Fieldset>
            <div className="p-grid p-field">
            <div className="col-6 p-md-6">Input BOL Number for Load<span className="required">*</span></div>
            <div className="col-6 p-md-6">
              <InputText
                value={bol}
                onChange={(e) => setbol(e.target.value)}
                required
                style={{width:"90%"}}
              />
            </div>
            <div className="col-6 p-md-6">
              <label>Select BOL Date<span className="required">*</span></label>
            </div>
            <div className="col-6 p-md-6">
              <Calendar selectionMode="single" onChange={(e) => setboldate(e.target.value)}
                value={boldate} selectOtherMonths="true" showIcon style={{width:"90%"}} />
            </div>
            <div className="col-6 p-md-6">
              <label>Quantity<span className="required">*</span></label>
            </div>
            <div className="col-6 p-md-6">
              <InputNumber value={qty} min={0} max={100000} onChange={(e) => setqty(e.value)} required style={{width:"90%"}}/>
            </div>
            <div className="col-6 p-md-6">
              <label>Select Shipper<span className="required">*</span></label>
            </div>
            <div className="col-6 p-md-6">
              <Dropdown
                id="Shipper"
                optionLabel="name"
                value={shipperName}
                options={shipperoptions}
                onChange={onShipperChange}
                placeholder="Select Shipper"
                editable
                disabled={false}
                style={{width:"90%"}}
                required
              />
            </div>
                
            <div className="col-12 p-md-12">
                <FileUpload
                  ref={fileTypeRef}
                  id="p-bol"
                  className=" p-upload-btn"
                  mode="advanced"
                  data-name="fileupload"
                  accept=".pdf,image/*"
                  maxFileSize={10000000}
                  customUpload
                  uploadHandler={onBasicUploadAuto}
                  auto
                  chooseLabel="Select&nbsp;File"
                  onRemove={clearDocs}
                  multiple={false}
                  previewWidth="0"
                  emptyTemplate={<p className="m-0">{bolfiledisplay}</p>}
                  style={{width:"95%"}}
                  min={0} max={1}
                /> <span className="required">* Please select file for submit</span>
            </div>
            <div className="col-12 p-md-12">
                <Button id="my-btn" onClick={(e) => confirm(e, onBasicUploadAuto)} label="Submit" >
                  &nbsp;<i className="pi pi-check"></i>
                </Button>
              </div>
            </div>
            </Fieldset>
          </div>
          <div className="col-4"></div>
        </div>
      </Card>
    </>
  );
};

export default DeliveredLoadInputFields;
