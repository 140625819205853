import React, { useState, useRef, useCallback, useEffect, createRef } from "react";
import "../../styles/_main.scss";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { useNavigate } from "react-router-dom";
import { InputTextarea } from "primereact/inputtextarea";
import { Calendar } from "primereact/calendar";
import { useStore, dispatch } from "../../hooks-store/store";
import { ProductService } from "../../service/ProductService";
import { InputText } from "primereact/inputtext";
import { InputNumber } from "primereact/inputnumber";
import { Checkbox } from "primereact/checkbox";
import { FileUpload } from "primereact/fileupload";
import moment from "moment";
import { Toast } from "primereact/toast";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Card } from "primereact/card";
import { Divider } from "primereact/divider";

const InputFields = (props) => {
  const navigate = useNavigate();
  const [state, dispatch] = useStore();
  const [poNumber, setPONumber] = useState("");
  const [tmwNumber, settmwNumber] = useState("");
  const [dDate, setdDate] = useState("");
  const [rdDate, setrdDate] = useState("");
  const [dTime, setdTime] = useState("");
  const [carrierName, setcarrierName] = useState("");
  const [profile, setProfile] = useState("");
  const [bol, setbol] = useState("");
  const [dlerror, setdlerror] = useState(false);
  const [llerror, setllerror] = useState(false);
  const [lDate, setlDate] = useState("");
  const [rlDate, setrlDate] = useState("");
  const [lTime, setlTime] = useState("");
  const [qty, setqty] = useState("");
  const [uom, setuom] = useState("");
  const [product, setproduct] = useState("");
  const [locName, setlocName] = useState("");
  const [llocName, setllocName] = useState("");
  const [address, setaddress] = useState("");
  const [laddress, setladdress] = useState("");
  const [bolfile, setbolfile] = useState("");
  const [bolfiledisplay, setbolfiledisplay] = useState("");
  const [comments, setcomments] = useState("");
  const [waybillfile, setwaybillfile] = useState("");
  const [waybillfiledisplay, setwaybillfiledisplay] = useState("");
  const [invoicefile, setinvoicefile] = useState("");
  let [invoicefiledisplay, setinvoicefiledisplay] = useState("");
  let [docsfiledisplay, setdocsfiledisplay] = useState("");
  const [cityStateZip, setcityStateZip] = useState("");
  const [lcityStateZip, setlcityStateZip] = useState("");
  const [cStatus, setcStatus] = useState([]);
  const [cStatuskey, setcStatuskey] = useState();
  const [lStatus, setlStatus] = useState();
  const [lStatuskey, setlStatuskey] = useState();
  const [iStatus, setiStatus] = useState();
  const [iStatuskey, setiStatuskey] = useState();
  const [ConfirmationOptions, setConfirmationOptions] = useState([]);
  const [LoadOptions, setLoadOptions] = useState([]);
  const [InvoiceOptions, setInvoiceOptions] = useState([]);
  const ConfirmationRef = useRef();
  const LoadRef = useRef();
  const InvoiceRef = useRef();
  const myToast = useRef(null);
  const fileTypeRegex = /^.*\.(tif|TIF|pjp|PJP|jfif|JFIF|ico|ICO|tiff|TIFF|gif|GIF|svg|SVG|xbm|XBM|jxl|JXL|jpeg|JPEG|svgz|SVGZ|jpg|JPG|webp|WEBP|png|PNG|bmp|BMP|pjpeg|PJPEG|avif|AVIF|pdf|PDF)$/;
  const bolRegex = /^[A-Za-z0-9-/_]*$/;
  const fileTypeRef = createRef();
  var [invoice, setinvoice] = useState();
  var [waybill, setwaybill] = useState();
  var [boldoc, setboldoc] = useState();
  var [docs, setdocs] = useState();
  const [formInputsValidity, setFormInputsValidity] = useState({
    dateRange: true,
    dateType: true,
  });

  const productService = new ProductService();
  const search = useLocation().search;

  const jsonPayload = {
    PONumber: poNumber,
    TMWNumber: tmwNumber,
    CarrierName: carrierName,
    Profile: profile,
    BOL: bol,
    RequestedLoadDate: rlDate ? new Date(rlDate).toLocaleDateString("en-US") : "",
    ActualLoadDate: lDate ? new Date(lDate).toLocaleDateString("en-US") : "",
    LoadTime: lTime,
    RequestedDeliveryDate: rdDate ? new Date(rdDate).toLocaleDateString("en-US") : "",
    ActualDeliveryDate: dDate ? new Date(dDate).toLocaleDateString("en-US") : "",
    DeliveryTime: dTime,
    DeliveryLocationName: locName,
    DeliveryLocationAddress: address,
    DeliveryLocationCity: cityStateZip.split(", ")[0] ? cityStateZip.split(", ")[0] : "",
    DeliveryLocationState: cityStateZip.split(", ")[1] ? cityStateZip.split(", ")[1] : "",
    DeliveryLocationZip: cityStateZip.split(", ")[2] ? cityStateZip.split(", ")[2] : "",
    LoadLocationName: llocName,
    LoadLocationAddress: laddress,
    LoadLocationCity: lcityStateZip.split(", ")[0] ? lcityStateZip.split(", ")[0] : "",
    LoadLocationState: lcityStateZip.split(", ")[1] ? lcityStateZip.split(", ")[1] : "",
    LoadLocationZip: lcityStateZip.split(", ")[2] ? lcityStateZip.split(", ")[2] : "",
    Quantity: qty,
    UOM: uom,
    LoadConfirmationStatus: cStatuskey,
    LoadStatus: lStatuskey,
    InvoiceStatus: iStatuskey,
    Product: product,
    Comments: comments,
    MeasuredIn: state.measuredin,
    IsDLError: dlerror,
    IsLLError: llerror,
    ProcessedBy: state.email,
  };
  var formdata = new FormData();
  useEffect(() => {
    let mounted = true;
    productService.CarrierLoadStatusFilters().then((data) => {
      if (mounted) {
        setConfirmationOptions(
          data[0].ConfirmationStatus.map((item) => ({
            name: item.Status,
            key: item.Key,
          }))
        );
        setLoadOptions(
          data[0].LoadStatus.map((item) => ({
            name: item.Status,
            key: item.Key,
          }))
        );
        setInvoiceOptions(
          data[0].InvoiceStatus.map((item) => ({
            name: item.Status,
            key: item.Key,
          }))
        );
      }
      return () => (mounted = false);
    });
    if (state.ponum) {
      onLoad();
    } else {
      OnClose();
    }
  }, [state.ponum]);

  const onDLError = (e) => {
    setdlerror(e.checked);
    if (!dlerror) {
      myToast.current.show({ severity: "info", summary: "Please Add Comments", detail: "Please enter the correct delivery location details in comments section", sticky: false });
    }
  };
  const onLLError = (e) => {
    setllerror(e.checked);
    if (!llerror) {
      myToast.current.show({ severity: "info", summary: "Please Add Comments", detail: "Please enter the correct load location details in comments section", sticky: false });
    }
  };

  function onBasicUpload(e) {
    dispatch("ISLOADING", true);
    try {
      var fd = new FormData();
      fd.append("file", e.files[0]);

      formdata = fd;
    } catch {}
    dispatch("ISLOADING", false);
  }

  const confirm = (e, funcName) => {
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      accept: () => funcName(e),
    });
  };

  const confirmComplete = (e, funcName) => {
    confirmDialog({
      message: "After marking Load Complete, no edits will be allowed. Are you sure you want to proceed?",
      header: "Confirmation",
      accept: () => funcName(e),
    });
  };

  var fd = new FormData();

  const onValueChange = (e) => {
    fd.append("Payload", e.target.value);
  };

  function onLoad() {
    try {
      
        dispatch("ISLOADING", true);
        const resp = productService.getCarrierDetails(state.ponum,state.tmwnum, state.email, state.filterstartdate, state.filterenddate);
        resp.then(function (result) {
          if (result !== undefined) {
            if (result[0].hasOwnProperty("FileInfo")  && result[0].FileInfo != null) {
              JSON.parse(result[0]["FileInfo"]).forEach((i) => {
                if (i["FileType"].toLowerCase().includes("bol")) {
                  setbolfiledisplay(i["FileName"]);
                } else if (i["FileType"].toLowerCase().includes("waybill")) {
                  setwaybillfiledisplay(i["FileName"]);
                } else if (i["FileType"].toLowerCase().includes("invoice")) {
                  setinvoicefiledisplay(i["FileName"]);
                } else if (i["FileType"].toLowerCase().includes("docs")) {
                  setdocsfiledisplay(i["FileName"]);
                }
              });
            }
            setPONumber(result[0]["PONumber"]);
            settmwNumber(result[0]["TMWNumber"]);
            setcarrierName(result[0]["CarrierName"]);
            //alert(result[0]["Profile"]);
            setProfile(result[0]["Profile"]);
            setbol(result[0]["BOL"]);
            setrlDate(result[0]["RequestedLoadDate"] == "1900-01-01" ? "" : new Date(moment(result[0]["RequestedLoadDate"]).format("MM/DD/YYYY")));
            setlDate(result[0]["ActualLoadDate"] == "1900-01-01" ? "" : new Date(moment(result[0]["ActualLoadDate"]).format("MM/DD/YYYY")));
            setlTime(result[0]["LoadTime"]);
            setrdDate(result[0]["RequestedDeliveryDate"] == "1900-01-01" ? "" : new Date(moment(result[0]["RequestedDeliveryDate"]).format("MM/DD/YYYY")));
            setdDate(result[0]["ActualDeliveryDate"] == "1900-01-01" ? "" : new Date(moment(result[0]["ActualDeliveryDate"]).format("MM/DD/YYYY")));
            setdTime(result[0]["DeliveryTime"]);
            setqty(result[0]["Quantity"]);
            setuom(result[0]["UOM"]);
            setproduct(result[0]["Product"]);
            setlocName(result[0]["DeliveryLocationName"]);
            setllocName(result[0]["LoadLocationName"]);
            setaddress(result[0]["DeliveryLocationAddress"]);
            setladdress(result[0]["LoadLocationAddress"]);
            setcomments(result[0]["Comments"]);
            setcStatus(result[0]["LoadConfirmationStatus"]);
            setcStatuskey(result[0]["LoadConfirmationStatuskey"]);
            setlStatus(result[0]["LoadStatus"]);
            setlStatuskey(result[0]["LoadStatuskey"]);
            setiStatus(result[0]["InvoiceStatus"]);
            setiStatuskey(result[0]["InvoiceStatuskey"]);
            setdlerror(result[0]["IsDLError"] == "0" ? false : true);
            setllerror(result[0]["IsLLError"] == "0" ? false : true);
            setcityStateZip(
              result[0]["DeliveryLocationCity"] ? result[0]["DeliveryLocationCity"] + ", " + result[0]["DeliveryLocationState"] + ", " + result[0]["DeliveryLocationZip"] : ""
            );
            setlcityStateZip(result[0]["LoadLocationCity"] ? result[0]["LoadLocationCity"] + ", " + result[0]["LoadLocationState"] + ", " + result[0]["LoadLocationZip"] : "");
            dispatch("ISLOADING", false);
          } else {
            myToast.current.show({ severity: "error", summary: "Invalid PO Number", detail: "Please enter a valid PO Number", sticky: false });
            settmwNumber(null);
            setcarrierName(null);
            setbol(null);
            setrlDate(null);
            setlDate(null);
            setlTime(null);
            setrdDate(null);
            setdDate(null);
            setdTime(null);
            setqty(null);
            setuom(null);
            setproduct(null);
            setlocName(null);
            setllocName(null);
            setaddress(null);
            setladdress(null);
            setcomments(null);
            setcStatus(null);
            setcStatuskey(null);
            setlStatus(null);
            setlStatuskey(null);
            setiStatus(null);
            setiStatuskey(null);
            setdlerror(null);
            setllerror(null);
            setcityStateZip(null);
            setlcityStateZip(null);
            dispatch("ISLOADING", false);
          }
        });
      
    } catch (ex) {}
  }

  function uploadBOL(e) {
    setboldoc(e.files[0]);
  }
  function clearBOL(e) {
    setboldoc();
  }
  function uploadWaybill(e) {
    setwaybill(e.files[0]);
  }
  function clearWaybill(e) {
    setwaybill();
  }
  function uploadInvoice(e) {
    setinvoice(e.files[0]);
  }
  function clearInvoice(e) {
    setinvoice();
  }
  function clearDocs(e) {
    setdocs();
  }
  const onBasicUploadAuto = async (e) => {
    try {
      if (e.files != null) {
        setdocs(e.files[0]);
      } else {
        if (carrierName == "" || carrierName == null) {
          myToast.current.show({ severity: "error", summary: "Carrier Name cannot be empty", detail: "Carrier Name is a mandatory field", sticky: false });
        } else if (!bolRegex.test(bol)) {
          myToast.current.show({ severity: "error", summary: "Invalid BOL", detail: "BOL cannot contain special characters except - / _", sticky: false });
        } else if ((dlerror || llerror) && (comments == "" || comments == null)) {
          myToast.current.show({ severity: "error", summary: "Comments cannot be empty", detail: "Please enter the correct location details in comments section", sticky: false });
        } else if (qty>state.limit){
          myToast.current.show({ severity: "error", summary: "Quantity cannot be greater than "+state.limit, detail: "Please enter a number less than or equal to "+state.limit, sticky: false });
        } else if (new Date(moment(lDate).format("MM/DD/YYYY")) > new Date(moment().format("MM/DD/YYYY"))) {
          myToast.current.show({ severity: "error", summary: "Invalid Actual Load Date", detail: "Actual Load Date cannot be a Future Date!", sticky: false });
        } else if (new Date(moment(dDate).format("MM/DD/YYYY")) > new Date(moment().format("MM/DD/YYYY"))) {
          myToast.current.show({ severity: "error", summary: "Invalid Actual Delivery Date", detail: "Actual Delivery Date cannot be a Future Date!", sticky: false });
        } else if (lDate!=null && dDate!=null && Date.parse(new Date(lDate).toLocaleDateString("en-US")) > Date.parse(new Date(dDate).toLocaleDateString("en-US"))) {
          myToast.current.show({ severity: "error", summary: "Invalid Actual Date Range", detail: "Actual Load Date cannot be ahead of Actual Delivery Date", sticky: false });
        } else {
          if (boldoc) {
            if (fileTypeRegex.test(boldoc.name)) {
              fd.append("bol", boldoc);
            } else {
              throw "Please upload an image or a pdf file for BOL";
            }
          }
          if (waybill) {
            if (fileTypeRegex.test(waybill.name)) {
              fd.append("waybill", waybill);
            } else {
              throw "Please upload an image or a pdf file for Waybill";
            }
          }
          if (invoice) {
            if (fileTypeRegex.test(invoice.name)) {
              fd.append("invoice", invoice);
            } else {
              throw "Please upload an image or a pdf file for Invoice";
            }
          }
          if (docs) {
            if (fileTypeRegex.test(docs.name)) {
              fd.append("docs", docs);
            } else {
              throw "Please upload an image or a pdf file for Additional Docs";
            }
          }
          fd.append("Payload", JSON.stringify(jsonPayload));
          dispatch("ISLOADING", true);
          const resp = await productService.postFileData(fd);
          if (resp == "200") {
            dispatch("ISLOADING", false);
            myToast.current.show({ severity: "info", summary: "Saved Successfully", detail: "Saved Successfully", sticky: false });
            onLoad(poNumber);
          } else {
            dispatch("ISLOADING", false);
            myToast.current.show({ severity: "error", summary: "Save Error", detail: `Error returned with status: ${resp}`, sticky: false });
          }
        }
      }
    } catch (ex) {
      dispatch("ISLOADING", false);
      myToast.current.show({ severity: "error", summary: "Save Error", detail: `Error while Saving: ${ex}`, sticky: false });
    }
  };

  const onLoadComplete = async (e) => {
    try {
      if (poNumber == "" || poNumber == null) {
        myToast.current.show({ severity: "error", summary: "PO Number cannot be empty", detail: "PO Number is a mandatory field", sticky: false });
      } else if (carrierName == "" || carrierName == null) {
        myToast.current.show({ severity: "error", summary: "Carrier Name cannot be empty", detail: "Carrier Name is a mandatory field", sticky: false });
      } else if (!bolRegex.test(bol)) {
        myToast.current.show({ severity: "error", summary: "Invalid BOL", detail: "BOL cannot contain special characters except - / _", sticky: false });
      } else if (bol == "" || bol == null) {
        myToast.current.show({ severity: "error", summary: "BOL cannot be empty", detail: "BOL is a mandatory field", sticky: false });
      } else if (tmwNumber == "" || tmwNumber == null) {
        myToast.current.show({ severity: "error", summary: "TMW number cannot be empty", detail: "TMW Number is a mandatory field", sticky: false });
      } else if (qty == "" || qty == null) {
        myToast.current.show({ severity: "error", summary: "Quantity cannot be empty", detail: "Quantity is a mandatory field", sticky: false });
      } else if ((dlerror || llerror) && (comments == "" || comments == null)) {
        myToast.current.show({ severity: "error", summary: "Comments cannot be empty", detail: "Please enter the correct location details in comments section", sticky: false });
      } else if (lDate == "" || lDate == null || lDate == "Invalid Date") {
        myToast.current.show({ severity: "error", summary: "Actual Load Date cannot be empty", detail: "Actual Load Date is a mandatory field", sticky: false });
      } else if (dDate == "" || dDate == null || dDate == "Invalid Date") {
        myToast.current.show({ severity: "error", summary: "Actual Delivery Date cannot be empty", detail: "Actual Delivery Date is a mandatory field", sticky: false });
      } else if (qty>state.limit){
        myToast.current.show({ severity: "error", summary: "Quantity cannot be greater than "+state.limit, detail: "Please enter a number less than or equal to "+state.limit, sticky: false });
      } else if (Date.parse(new Date(lDate).toLocaleDateString("en-US")) > Date.parse(new Date(dDate).toLocaleDateString("en-US"))) {
        myToast.current.show({ severity: "error", summary: "Invalid Actual Date Range", detail: "Actual Load Date cannot be ahead of Actual Delivery Date", sticky: false });
      } else if (new Date(moment(lDate).format("MM/DD/YYYY")) > new Date(moment().format("MM/DD/YYYY"))) {
        myToast.current.show({ severity: "error", summary: "Invalid Actual Load Date", detail: "Actual Load Date cannot be a Future Date!", sticky: false });
      } else if (new Date(moment(dDate).format("MM/DD/YYYY")) > new Date(moment().format("MM/DD/YYYY"))) {
        myToast.current.show({ severity: "error", summary: "Invalid Actual Delivery Date", detail: "Actual Delivery Date cannot be a Future Date!", sticky: false });
      }  else {
        if (boldoc) {
          if (fileTypeRegex.test(boldoc.name)) {
            fd.append("bol", boldoc);
          } else {
            throw "Please upload an image or a pdf file for BOL";
          }
        }
        if (waybill) {
          if (fileTypeRegex.test(waybill.name)) {
            fd.append("waybill", waybill);
          } else {
            throw "Please upload an image or a pdf file for Waybill";
          }
        }
        if (invoice) {
          if (fileTypeRegex.test(invoice.name)) {
            fd.append("invoice", invoice);
          } else {
            throw "Please upload an image or a pdf file for Invoice";
          }
        }
        if (docs) {
          if (fileTypeRegex.test(docs.name)) {
            fd.append("docs", docs);
          } else {
            throw "Please upload an image or a pdf file for Additional Docs";
          }
        }

        fd.append("Payload", JSON.stringify(jsonPayload));
        dispatch("ISLOADING", true);
        const resp = await productService.postCompleteLoadData(fd);
        if (resp == "200") {
          dispatch("ISLOADING", false);
          myToast.current.show({ severity: "info", summary: "Load Completed Successfully", detail: "To Submit Payment, please upload BOL and Invoice under Supporting Documents", sticky: false });
          onLoad(poNumber);
        } else {
          dispatch("ISLOADING", false);
          myToast.current.show({ severity: "error", summary: "Save Error", detail: `Error returned with status: ${resp}`, sticky: false });
        }
      }
    } catch (ex) {
      dispatch("ISLOADING", false);
      myToast.current.show({ severity: "error", summary: "Save Error", detail: `Error while Saving: ${ex}`, sticky: false });
    }
  };

  const onSubmitDocs = async (e) => {
    try {
      if (poNumber == "" || poNumber == null) {
        myToast.current.show({ severity: "error", summary: "PO Number cannot be empty", detail: "PO Number is a mandatory field", sticky: false });
      } else if (carrierName == "" || carrierName == null) {
        myToast.current.show({ severity: "error", summary: "Carrier Name cannot be empty", detail: "Carrier Name is a mandatory field", sticky: false });
      } else if (bol == "" || bol == null) {
        myToast.current.show({ severity: "error", summary: "BOL cannot be empty", detail: "BOL is a mandatory field", sticky: false });
      } else if (tmwNumber == "" || tmwNumber == null) {
        myToast.current.show({ severity: "error", summary: "TMW number cannot be empty", detail: "TMW Number is a mandatory field", sticky: false });
      } else if (lDate == "" || lDate == null || lDate == "Invalid Date") {
        myToast.current.show({ severity: "error", summary: "Actual Load Date cannot be empty", detail: "Actual Load Date is a mandatory field", sticky: false });
      } else if (dDate == "" || dDate == null || dDate == "Invalid Date") {
        myToast.current.show({ severity: "error", summary: "Actual Delivery Date cannot be empty", detail: "Actual Delivery Date is a mandatory field", sticky: false });
      } else if (qty == "" || qty == null) {
        myToast.current.show({ severity: "error", summary: "Quantity cannot be empty", detail: "Quantity is a mandatory field", sticky: false });
      } else if ((dlerror || llerror) && (comments == "" || comments == null)) {
        myToast.current.show({ severity: "error", summary: "Comments cannot be empty", detail: "Please enter the correct location details in comments section", sticky: false });
      } else if (Date.parse(new Date(lDate).toLocaleDateString("en-US")) > Date.parse(new Date(dDate).toLocaleDateString("en-US"))) {
        myToast.current.show({ severity: "error", summary: "Invalid Actual Date Range", detail: "Actual Load Date cannot be ahead of Actual Delivery Date", sticky: false });
      } else if (boldoc || waybill || invoice || docs || bolfiledisplay || waybillfiledisplay || invoicefiledisplay || docsfiledisplay) {
        if (boldoc) {
          if (fileTypeRegex.test(boldoc.name)) {
            fd.append("bol", boldoc);
          } else {
            throw "Please upload an image or a pdf file for BOL";
          }
        }
        if (waybill) {
          if (fileTypeRegex.test(waybill.name)) {
            fd.append("waybill", waybill);
          } else {
            throw "Please upload an image or a pdf file for Waybill";
          }
        }
        if (invoice) {
          if (fileTypeRegex.test(invoice.name)) {
            fd.append("invoice", invoice);
          } else {
            throw "Please upload an image or a pdf file for Invoice";
          }
        }
        if (docs) {
          if (fileTypeRegex.test(docs.name)) {
            fd.append("docs", docs);
          } else {
            throw "Please upload an image or a pdf file for Additional Docs";
          }
        }
        fd.append("Payload", JSON.stringify(jsonPayload));
        dispatch("ISLOADING", true);
        const resp = await productService.postSubmitDocs(fd);
        if (resp == "200") {
          dispatch("ISLOADING", false);
          myToast.current.show({ severity: "info", summary: "Saved Successfully", detail: "Saved Successfully", sticky: false });
          onLoad(poNumber);
        } else {
          dispatch("ISLOADING", false);
          myToast.current.show({ severity: "error", summary: "Save Error", detail: `Error returned with status: ${resp}`, sticky: false });
        }
      } else {
        myToast.current.show({ severity: "error", summary: "No file uploaded", detail: `Please upload at least one file to Save & Submit payment`, sticky: false });
      }
    } catch (ex) {
      dispatch("ISLOADING", false);
      myToast.current.show({ severity: "error", summary: "Save Error", detail: `Error while Saving: ${ex}`, sticky: false });
    }
  };

  function OnClose() {
    navigate("/", { replace: true });
  }

  const onCStatusChange = (e) => {
    setcStatus(e.value.name);
    setcStatuskey(e.value.key);
  };
  const onLStatusChange = (e) => {
    setlStatus(e.value.name);
    setlStatuskey(e.value.key);
  };
  const onIStatusChange = (e) => {
    setiStatus(e.value.name);
    setiStatuskey(e.value.key);
  };
  return (
    <>
      <Toast ref={myToast} />
      <h4 className="p-p-3 main-title">Carrier Load Information Portal</h4>
      <Card className="p-card">
        <div className="p-grid">
          <div className="col-5">
            <div className="p-grid p-field p-md-6">
              <div className="ml-0 mt-1">
                <span className="p-float-label">
                  <InputText id="PoNumber" value={poNumber} onChange={(e) => setPONumber(e.value)} required readOnly disabled />
                  <label>PO Number</label>
                </span>
              </div>
            </div>
            <div className="p-grid p-field p-md-6">
              <span className="p-float-label">
                <InputText value={tmwNumber} onChange={(e) => settmwNumber(e.target.value)} required readOnly disabled />
                <label>TMW Number</label>
              </span>
            </div>
            <div className="p-grid p-field p-md-6">
              <span className="p-float-label" style={{ width: "100%" }}>
                <InputText value={carrierName} style={{ width: "100%" }} onChange={(e) => setcarrierName(e.target.value)} required readOnly disabled />
                <label>Carrier Name</label>
              </span>
            </div>
            <div className="p-grid p-field p-md-6">
              <span className="p-float-label">
                <InputText
                  value={bol}
                  onChange={(e) => setbol(e.target.value)}
                  required
                  disabled={lStatus !== "In Process" || iStatus === "Submitted" ? true : false}
                  className={lStatus !== "In Process" || iStatus === "Submitted" ? null : "simple-highlight"}
                />
                <label>
                  BOL<span className="required">*</span>
                </label>
              </span>
            </div>

            <Divider align="left" className="col-10">
              Load Details
            </Divider>
            <div className="p-grid p-field p-md-0">
              <div className="m-1 ml-2 mt-3">
                <span className="p-float-label">
                  <InputNumber
                    inputId="locale-us"
                    value={qty}
                    onValueChange={(e) => setqty(e.target.value)}
                    mode="decimal"
                    locale="en-US"
                    minFractionDigits={2}
                    min={0}
                    disabled={lStatus !== "In Process" || iStatus === "Submitted" ? true : false}
                    inputClassName={lStatus !== "In Process" || iStatus === "Submitted" ? null : "simple-highlight"}
                  />
                  <label>
                    Quantity<span className="required">*</span>
                  </label>
                </span>
              </div>
              <div className="m-1 mt-3">
                <span className="p-float-label">
                  <InputText value={uom} readOnly disabled onChange={(e) => setuom(e.target.value)} />
                  <label>UOM</label>
                </span>
              </div>
              <div className="m-1 mt-3">
                <span className="p-float-label">
                  <InputText value={product} readOnly disabled onChange={(e) => setproduct(e.target.value)} />
                  <label>Commodity (Product)</label>
                </span>
              </div>
            </div>
            <div className="p-grid p-field pt-2">
              <div className="m-1 ml-2">
                <span className="p-float-label ">
                  <Calendar value={rlDate} onChange={(e) => new Date(setrlDate(e.target.value))} selectionMode="single" selectOtherMonths="true" disabled />
                  <label>Req. Load Date</label>
                </span>
              </div>
              <div className="m-1">
                <span className="p-float-label p-0">
                  <Calendar
                    value={lDate}
                    onChange={(e) => new Date(setlDate(e.target.value))}
                    selectionMode="single"
                    selectOtherMonths="true"
                    disabled={lStatus !== "In Process" || iStatus === "Submitted" ? true : false}
                    inputClassName={lStatus !== "In Process" || iStatus === "Submitted" ? null : "simple-highlight"}
                  />
                  <label>
                    Actual Load Date<span className="required">*</span>
                  </label>
                </span>
              </div>
            </div>
            <div className="p-grid p-field pt-2">
              <div className="m-1 ml-2">
                <span className="p-float-label">
                  <Calendar value={rdDate} onChange={(e) => setrdDate(e.target.value)} selectionMode="single" selectOtherMonths="true" disabled />
                  <label>Req. Delivery Date</label>
                </span>
              </div>
              <div className="m-1">
                <span className="p-float-label">
                  <Calendar
                    value={dDate}
                    onChange={(e) => setdDate(e.target.value)}
                    selectionMode="single"
                    selectOtherMonths="true"
                    disabled={lStatus !== "In Process" || iStatus === "Submitted" ? true : false}
                    inputClassName={lStatus !== "In Process" || iStatus === "Submitted" ? null : "simple-highlight"}
                  />
                  <label>
                    Actual Delivery Date<span className="required">*</span>
                  </label>
                </span>
              </div>
            </div>

            <Divider align="left" className="col-10">
              Comments
            </Divider>
            <div className="p-grid p-field pt-2">
              <span className="p-float-label">
                <InputTextarea
                  autoResize="false"
                  rows={5}
                  cols={100}
                  value={comments}
                  onChange={(e) => setcomments(e.target.value)}
                  disabled={iStatus === "Submitted" ? true : false}
                  style={{ width: "100%" }}
                />
              </span>
            </div>
            <div className="p-grid p-fluid justify-content-end">
              <text>
                <small>
                  <span className="required">*</span>&nbsp;Required Fields
                </small>
              </text>
            </div>
          </div>
          <Divider layout="vertical" className="pl-5"></Divider>
          <div className="col-6">
            <Divider align="left" className="col-9">
              Current Status
            </Divider>
            <div className="p-grid p-field pt-2">
              <div className="m-1 ml-2">
                <span className="p-float-label">
                  <Dropdown
                    id="cStatus"
                    optionLabel="name"
                    value={cStatus}
                    options={ConfirmationOptions}
                    onChange={onCStatusChange}
                    placeholder="Confirmation Status"
                    editable
                    disabled={lStatus !== "Not Started" && lStatus !== "Declined" ? true : false}
                    className={lStatus === "Not Started" ? "simple-highlight" : null}
                  />
                  <label htmlFor="cStatus">Confirmation Status</label>
                </span>
              </div>
              <div className="m-1 ml-2">
                <span className="p-float-label">
                  <Dropdown id="lStatus" value={lStatus} options={LoadOptions} onChange={onLStatusChange} optionLabel="name" ref={LoadRef} editable disabled />
                  <label htmlFor="lStatus">Load Status</label>
                </span>
              </div>
              <div className="m-1 ml-2">
                <span className="p-float-label">
                  <Dropdown id="iStatus" value={iStatus} options={InvoiceOptions} onChange={onIStatusChange} optionLabel="name" ref={InvoiceRef} editable disabled />
                  <label htmlFor="iStatus">Invoice Status</label>
                </span>
              </div>
            </div>
            <Divider align="left" className="col-9">
              Load Location
            </Divider>
            <div className="p-grid p-field pt-2">
              <div className="m-1 ml-2" style={{ width: "21%" }}>
                <span className="p-float-label">
                  <InputText value={llocName} onChange={(e) => setllocName(e.target.value)} readOnly disabled style={{ width: "100%" }} />
                  <label>Name</label>
                </span>
              </div>
              <div className="m-1 ml-2" style={{ width: "21%" }}>
                <span className="p-float-label">
                  <InputText value={laddress} onChange={(e) => setladdress(e.target.value)} readOnly disabled style={{ width: "100%" }} />
                  <label>Address</label>
                </span>
              </div>
              <div className="m-1 ml-2" style={{ width: "21%" }}>
                <span className="p-float-label">
                  <InputText value={lcityStateZip} onChange={(e) => setlcityStateZip(e.target.value)} readOnly disabled style={{ width: "100%" }} />
                  <label>City, State, Zip</label>
                </span>
              </div>
            </div>
            <div className="p-grid p-field">
              <div className="m-1 ml-2">
                <Checkbox inputId="binary" checked={llerror} onChange={onLLError} disabled={lStatus !== "In Process" || iStatus === "Submitted" ? true : false} />
                &nbsp;Check box if error in Load Location
              </div>
            </div>
            <Divider align="left" className="col-9">
              Delivery Location
            </Divider>
            <div className="p-grid p-field pt-2">
              <div className="m-1 ml-2" style={{ width: "21%" }}>
                <span className="p-float-label">
                  <InputText value={locName} readOnly onChange={(e) => setlocName(e.target.value)} disabled style={{ width: "100%" }} />
                  <label>Name</label>
                </span>
              </div>
              <div className="m-1 ml-2" style={{ width: "21%" }}>
                <span className="p-float-label">
                  <InputText value={address} readOnly onChange={(e) => setaddress(e.target.value)} disabled style={{ width: "100%" }} />
                  <label>Address</label>
                </span>
              </div>
              <div className="m-1 ml-2" style={{ width: "21%" }}>
                <span className="p-float-label">
                  <InputText value={cityStateZip} readOnly onChange={(e) => setcityStateZip(e.target.value)} disabled style={{ width: "100%" }} />
                  <label>City, State, Zip</label>
                </span>
              </div>
            </div>
            <div className="p-grid p-field">
              <div className="m-1 ml-2">
                <Checkbox inputId="binary" checked={dlerror} onChange={onDLError} disabled={lStatus !== "In Process" || iStatus === "Submitted" ? true : false} />
                &nbsp;Check box if error in Delivery Location
              </div>
            </div>
            <Divider align="left" className={(lStatus === "Edited" || lStatus === "Complete") && iStatus !== "Submitted" ? "col-9 simple-highlight" : "col-9"}>
              Supporting Documents
            </Divider>
            <div className="p-grid p-field">
            <div className="m-1">
                <FileUpload
                  ref={fileTypeRef}
                  mode="advanced"
                  name="fileupload"
                  accept=".pdf,.docx,image/*"
                  maxFileSize={10000000}
                  customUpload
                  uploadHandler={onBasicUploadAuto}
                  auto
                  chooseLabel="Add Docs"
                  onRemove={clearDocs}
                  multiple={false}
                  emptyTemplate={<p className="m-0">{docsfiledisplay}</p>}
                />
              </div>
              <div style={{visibility: profile === '2'? "hidden" : "visible" }} className={(lStatus === "Edited" || lStatus === "Complete") && iStatus !== "Submitted" ? "m-1 ml-2 simple-highlight" : "m-1 ml-2"}>
                <FileUpload
                  ref={fileTypeRef}
                  id="p-bol"
                  //className=" p-upload-btn"
                  mode="advanced"
                  data-name="fileupload"
                  accept=".pdf,image/*"
                  maxFileSize={10000000}
                  customUpload
                  uploadHandler={uploadBOL}
                  auto
                  chooseLabel="BOL"
                  onRemove={clearBOL}
                  multiple={false}
                  previewWidth="0"
                  emptyTemplate={<p className="m-0">{bolfiledisplay}</p>}
                  className={(lStatus === "Edited" || lStatus === "Complete") && iStatus !== "Submitted" ? "p-upload-btn simple-highlight" : "p-upload-btn"}
                />
              </div>
              <div style={{visibility: profile === '2' ? "hidden" : "visible" }} className="m-1">
                <FileUpload
                  ref={fileTypeRef}
                  className="p-upload-btn"
                  mode="advanced"
                  name="fileupload"
                  accept=".pdf,image/*"
                  maxFileSize={10000000}
                  customUpload
                  uploadHandler={uploadWaybill}
                  auto
                  chooseLabel="WayBill"
                  onRemove={clearWaybill}
                  multiple={false}
                  emptyTemplate={<p className="m-0">{waybillfiledisplay}</p>}
                />
              </div>
              <div style={{visibility: profile === '2' ? "hidden" : "visible" }} className={(lStatus === "Edited" || lStatus === "Complete") && iStatus !== "Submitted" ? "m-1 simple-highlight" : "m-1"}>
                <FileUpload
                  ref={fileTypeRef}
                  //className="p-upload-btn"
                  mode="advanced"
                  name="fileupload"
                  accept=".pdf,image/*"
                  maxFileSize={10000000}
                  customUpload
                  uploadHandler={uploadInvoice}
                  auto
                  chooseLabel="Invoice"
                  onRemove={clearInvoice}
                  multiple={false}
                  emptyTemplate={<p className="m-0">{invoicefiledisplay}</p>}
                  className={(lStatus === "Edited" || lStatus === "Complete") && iStatus !== "Submitted" ? "p-upload-btn simple-highlight" : "p-upload-btn"}
                />
              </div>
            </div>
            <Divider align="left" className="col-9" />
            <div className="p-grid p-field">
              <div className="ml-2">
                <Button id="my-btn" onClick={(e) => confirm(e, onBasicUploadAuto)} label="Save" disabled={iStatus === "Submitted" ? true : false} />
              </div>
              <div className="pl-2">
                <Button
                  onClick={(e) => confirmComplete(e, onLoadComplete)}
                  label="Save &amp; Submit Load Complete"
                  className="button-nowrap"
                  disabled={lStatus == "In Process" && cStatus == "Accepted" && iStatus !== "Submitted" ? false : true}
                />
              </div>
              <div className="pl-2">
                <Button
                  onClick={(e) => confirm(e, onSubmitDocs)}
                  className="button-nowrap"
                  label="Save &amp; Submit Payment"
                  disabled={
                    profile ==='2' ?
                    (iStatus != "Submitted" && (lStatus == "Complete" || lStatus == "Edited") ? false : true ) : 
                    (((boldoc || bolfiledisplay) && (invoice || invoicefiledisplay))) && iStatus != "Submitted" && (lStatus == "Complete" || lStatus == "Edited") ? false : true
                  }
                />
              </div>
              <div className="pl-2">
                <Button label="Close" className="p-button-secondary" onClick={(event) => OnClose(event)} />
              </div>
            </div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default InputFields;
