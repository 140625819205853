import React, { useState } from "react";
import Filters from "./Filters";
import Table from "./Table";
import { useStore } from "../../hooks-store/store";
//import Fields from "./InputFields";

const LandingPage = (props) => {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedDateType, setSeletecDateType] = useState(null);
  const [selectedPoNumber, setSeletedPoNumber] = useState(null);
  const [selectedTMWNumber, setSelectedTMWNumber] = useState(null);
  const [selectedBOLNumber, setSelectedBOLNumber] = useState(null);
  const [selectedRailNumber, setSelectedRailNumber] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [activeFilters, setActiveFilters] = useState(false);
  const [state, dispatch] = useStore();
  const [selectedOriginFilter, setSelectedOriginFilter] = useState([]);
  const [selectedDestinationFilter, setSelectedDestinationFilter] = useState([]);
  const [selectedOrderStatusFilter, setSelectedOrderStatusFilter] = useState([]);
  const [selectedWAMStatusFilter, setSelectedWAMStatusFilter] = useState([]);
  const [selectedConfirmationFilter, setSelectedConfirmationFilter] = useState([]);
  const [selectedLoadFilter, setSelectedLoadFilter] = useState([]);
  const [selectedInvoiceFilter, setSelectedInvoiceFilter] = useState([]);
  const [selectedSupplierFilter, setSelectedSupplierFilter] = useState([]);
  const [radioValue, setRadioValue] = useState(null);

  return (
    <div>
      <h4 className="p-p-3 main-title">Carrier Load Information Portal</h4>
      <Filters/>
      <Table
        selectedStartDate={selectedStartDate}
        selectedEndDate={selectedEndDate}
        selectedDateType={selectedDateType}
        selectedPoNumber={selectedPoNumber}
        selectedTMWNumber={selectedTMWNumber}
        selectedBOLNumber={selectedBOLNumber}
        selectedRailNumber={selectedRailNumber}
        selectedStatus={selectedStatus}
        // handleActiveFilters={handleActiveFilters}
        activeFilters={activeFilters}
        selectedOriginFilter={selectedOriginFilter}
        selectedDestinationFilter={selectedDestinationFilter}
        selectedOrderStatusFilter={selectedOrderStatusFilter}
        selectedWAMStatusFilter={selectedWAMStatusFilter}
        selectedConfirmationFilter={selectedConfirmationFilter}
        selectedLoadFilter={selectedLoadFilter}
        selectedInvoiceFilter={selectedInvoiceFilter}
        selectedSupplierFilter={selectedSupplierFilter}
        radioValue={radioValue}
        username={state.username}
      />
    </div>
  );
};

export default LandingPage;
