import React, { useState, useEffect, useRef } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { apiConfig } from "./apiConfig";
import Navigation from "./components/Nav";
import Version from "./components/Version";
import { useStore } from "./hooks-store/store";
import NoAccess from "./components/NoAccess";
import Maintenance from "./components/Maintenance";
import NoResults from "./components/NoResults";
import Fields from "./components/CarrierInputData/InputFields";
import DeliveredLoad from "./components/DeliveredLoadInputData/DeliveredLoadInputFields";
import LandingPage from "./components/LandingPage/LandingPage";
import Report from "./components/Report/Report";
import { InteractionRequiredAuthError, InteractionStatus, ServerError } from "@azure/msal-browser";
import { Navigate } from "react-router-dom";
import { MsalAuthenticationTemplate, useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { ProgressSpinner } from "primereact/progressspinner";
import { Button } from "primereact/button";
import { ProductService } from "./service/ProductService";
import PasswordReset from "./components/PasswordReset";
import "./App.css";

function ProtectedComponent() {
  const { instance, inProgress, accounts } = useMsal();
  const [apiData, setApiData] = useState(null);
  const [state, dispatch] = useStore();
  const [admin, setAdmin] = useState(true);
  const [fullname, setfullname] = useState("");
  const [carrier, setcarrier] = useState("");
  const [isUnderMaintenance, setIsUnderMaintenance] = useState("");
  const productService = new ProductService();
  useEffect(() => {
    setIsUnderMaintenance(0);
    const accessTokenRequest = {
      scopes: [apiConfig],
      account: accounts[0],
    };
    
    if (!apiData && inProgress === InteractionStatus.None) {
      dispatch("ISLOADING", true);
      try {
        instance
        .acquireTokenSilent(accessTokenRequest)
        .then((accessTokenResponse) => {
          //Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          console.log("************ Access token acquired ************");
          dispatch("USERNAME", accounts[0].name);
          console.log("Htting userinfo api");
          
          productService.getUserInfo(accounts[0].username)
          .then((data) => {
            const isinternal = data["CarrierInfo"][0].IsInternal;
            const isadmin = data["CarrierInfo"][0].IsAdmin;
            const isactive = data["CarrierInfo"][0].IsActive;
            const Fullname = data["CarrierInfo"][0].FullName;
            const Carrier = data["CarrierInfo"][0].Carrier;
            dispatch("FULLNAME", data["CarrierInfo"][0].FullName);
            dispatch("EMAIL", data["CarrierInfo"][0].EmailAddress);
            setfullname(Fullname);
            setcarrier(Carrier);
            setAdmin(isadmin);
            setApiData(data);
            dispatch("ISINTERNAL", isinternal);
            dispatch("PONUM", null);
            dispatch("ISLOADING", false);
            dispatch("ISACTIVE", isactive);
            dispatch("CARRIER",Carrier);
            
            productService.Log_User_Action({
              "User":data["CarrierInfo"][0].EmailAddress,
              "Application":"CP",
              "Module":"Load Information",
              "Action":"Log In",
              "ActionDesc":"User logged in to CP",
              "AccessDatetime": new Date()
            }).then((response) => {
              if (response !== "User action saved." || response === null)
              {
                alert("Log Error!\n\nPlease report this error to the IT team and continue with your task. It will not be affected.\nThanks!");
              
              }
            })
          })

          productService.fetchMaintenancePageSetting().then((data) => {
            setIsUnderMaintenance(data.data);
          })
        })

        
      }
      catch(error) {
          console.log("in catch");
      }
    } else {
      <Navigate to="./Error" />;
    }
  }, [accounts, inProgress]);

  function signOutClickHandler(instance) {
    const logoutRequest = {
      account: accounts[0],
      postLogoutRedirectUri: "/",
    };
    instance.logoutRedirect(logoutRequest);
  }

  function SignOutButton() {
    const { instance } = useMsal();

    return <Button onClick={() => signOutClickHandler(instance)} className="p-button-sm" label="Sign Out" />;
  }

  return (
    <>
      <div>
        {state.isloading ? (
          <>
            <div className="spinner-dark-bg">
              <div className="inner-spinner-bg">
                <ProgressSpinner />
              </div>
            </div>
          </>
        ) : null}
        <Router>
          <Navigation user={fullname} sbutton={SignOutButton()} carrier={carrier} />
          <div className="App">
            <div className="p-m-4 padder-top">
              
              <Routes>
                {isUnderMaintenance == "1" ? <Route path="/" element={<Maintenance />} /> : null}
                {isUnderMaintenance == "0" && state.isinternal === "N" && state.isactive === "Y" ? <Route path="/InputData" element={<Fields />} /> : null}
                {isUnderMaintenance == "0" && state.isinternal === "N" && state.carrier === process.env.REACT_APP_DeliveredLoadCarrierName && state.isactive === "Y" ? <Route path="/" element={<DeliveredLoad />} /> : null}
                {isUnderMaintenance == "0" && state.isinternal === "Y" && state.isactive === "Y" ? <Route path="/" element={<Report />} /> : null}
                {isUnderMaintenance == "0" && state.isinternal === "N" && state.carrier !== process.env.REACT_APP_DeliveredLoadCarrierName && state.isactive === "Y" ? <Route path="/" element={<LandingPage />} /> : null}
                {isUnderMaintenance == "0" && state.isinternal === null || state.isactive === null ? <Route path="/" element={<NoResults />} /> : null}
                {isUnderMaintenance == "0" && (state.isinternal !== "N" && state.isinternal !== "Y") || state.isactive === "N" ? <Route path="/" element={<NoAccess />} /> : null}
              </Routes>
            </div>
          </div>
        </Router>
      </div>
      {state.isactive === "Y" ? <Version /> : null}
    </>
  );
}

const authRequest = {
  scopes: ["openid", apiConfig],
};

const App = () => {
  return (
    <>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={authRequest} errorComponent={PasswordReset}>
        <ProtectedComponent />
      </MsalAuthenticationTemplate>
    </>
  );
};

export default App;
