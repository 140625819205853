import preval from 'preval.macro';
import moment from 'moment';
import React from "react";

const Version = () => {
    const buildTimestamp = preval`module.exports = new Date().getTime();`;
    const getDateString = () => {
        const lastUpdateMoment = moment.unix(buildTimestamp / 1000);
        const formattedDate = lastUpdateMoment.format('MM/D/YY');

        return formattedDate;
    };

    return (
        <div className="version-container">
            version: 1.1.0 published {getDateString()}
        </div>
    );
}

export default Version;
