import React, { useState, useRef, useEffect, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ToggleButton } from "primereact/togglebutton";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Tag } from "primereact/tag";
import { Toast } from "primereact/toast";
import { ProductService } from "../../service/ProductService";
import { useStore } from "../../hooks-store/store";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import { NumericFormat } from "react-number-format";

const CarrierData = (props) => {
  const [tabledata, SetTableData] = useState([]);
  const [state, dispatch] = useStore();
  const productService = new ProductService();
  const [showLoadLoc, setShowLoadLoc] = useState(null);
  const [LoadLoc, setLoadLoc] = useState(null);
  const [ShowDeliverLoc, setShowDeliverLoc] = useState(null);
  const [DeliverLoc, setDeliverLoc] = useState(null);
  const [ShowDoc, setShowDoc] = useState(null);
  const [Doc, setDoc] = useState(null);
  const userName = state.username;
  const myToast = useRef(null);
  let navigate = useNavigate();
  
  useEffect(() => {
    setShowLoadLoc("none");
    setShowDeliverLoc("none");
    setShowDoc("none");
    fetchcpLandingReport();
    dispatch("SEARCHCP", null);
  }, []);

  const fetchcpLandingReport = useCallback(async () => {
    dispatch("ISLOADING", true);
    let mounted = true;
    try {
      const response = await productService.GetUserSpecificCarrierData(
        state.email,
        state.filterstartdate,
        state.filterenddate,
        state.filterpo ? state.filterpo : "",
        state.filtertmw ? state.filtertmw : "",
        state.filterbol ? state.filterbol : "",
        state.cnfrmstatus?state.cnfrmstatus.map((item) => {
            return { name: item.key }
          }):null,
        state.loadstatus?state.loadstatus.map((item) => {
            return { name: item.key }
          }):null,
        state.invstatus?state.invstatus.map((item) => {
            return { name: item.key }
          }):null
        );

      if (!response.length > 0) {
        SetTableData([]);
        dispatch("ISLOADING", false);
        dispatch("SEARCHCP", null);
      }
      const data = await response;

      if (mounted) {
        if (data.length > 0) {
          SetTableData(data);
        }
        dispatch("SEARCHCP", null);
        dispatch("ISLOADING", false);
      }
    } catch (error) {
      SetTableData([]);
      dispatch("ISLOADING", false);
      dispatch("SEARCHCP", null);
    }

    return () => (mounted = false);
  }, [state.searchCP]);

  useEffect(() => {
    if (state.searchCP !== null) {
      fetchcpLandingReport();
    }
  }, [state.searchCP]);

  const renderDetails = (tabledata) => {
    const customFilteredData = tabledata.map((items) => {
      let properties = {
        ...items,
      };
      return properties;
    });
    return customFilteredData;

  };

  var OpenBOLEntry = function(event, param, param1, limit, measuredIn){
    dispatch("PONUM", param);
    dispatch("TMWNUMBER",param1);
    dispatch("LIMIT",limit);
    dispatch("MEASUREDIN",measuredIn);
    /*dispatch("STARTDATE",startDate);
    dispatch("ENDDATE",endDate);*/
  };

  var GetFile = function(event, param1, param2, param3, param4){
    event.preventDefault();
    const fileExt = param1.split('.').pop() === "pdf" ? "application/pdf" : `image/${param1.split('.').pop()}`;
    const fileName = param4 === "Submitted" ? `Archive\\${param1}` : param1;
    productService.GetBlobFile(fileName, param2, param3)
    .then((response) => {
      if (response.status !== 200) {
        dispatch("ISLOADING", false);
        myToast.current.show({ severity: "error", summary: "File Load Error", detail: `Error returned with status: ${response.status}`, sticky: false });
      }
      else {
        const file = new Blob([response.data], {type: `${fileExt}`});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    });
  };

  var GetFile = function(event, param1, param2, param3, param4){
    event.preventDefault();
    const fileExt = param1.split('.').pop() === "pdf" ? "application/pdf" : `image/${param1.split('.').pop()}`;
    const fileName = param4 === "Submitted" ? `Archive\\${param1}` : param1;
    productService.GetBlobFile(fileName, param2, param3)
    .then((response) => {
      if (response.status !== 200) {
        dispatch("ISLOADING", false);
        myToast.current.show({ severity: "error", summary: "File Load Error", detail: `Error returned with status: ${response.status}`, sticky: false });
      }
      else {
        const file = new Blob([response.data], {type: `${fileExt}`});
        const fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      }
    });
  };

  const LoadConfirmationStatus = (rowData) => {
    if (rowData.LoadConfirmationStatus === "Offered") return <Tag severity="info">Offered</Tag>;
    if (rowData.LoadConfirmationStatus === "Accepted") return <Tag severity="success">Accepted</Tag>;
    if (rowData.LoadConfirmationStatus === "Declined") return <Tag severity="danger">Declined</Tag>;
  };
  const PONumber = (rowData) => {
    return rowData.PONumber;
  };

  const TMWNumber = (rowData) => {
    return <Link to="/InputData"  onClick={event => OpenBOLEntry(event, rowData.PONumber,rowData.TMWNumber, rowData.Limit, rowData.MeasuredIn)}>{rowData.TMWNumber}</Link>;

  };
  const CarrierName = (rowData) => {
    return rowData.CarrierName;
  };
  const BOL = (rowData) => {
    return rowData.BOL;
  };
  const RequestedLoadDate = (rowData) => {
    return rowData.RequestedLoadDate;
  };
  const ActualLoadDate = (rowData) => {
    return rowData.ActualLoadDate== "1900-01-01" ? "" : rowData.ActualLoadDate;
  };
  const RequestedDeliveryDate = (rowData) => {
    return rowData.RequestedDeliveryDate;
  };
  const ActualDeliveryDate = (rowData) => {
    return rowData.ActualDeliveryDate == "1900-01-01" ? "" : rowData.ActualDeliveryDate;
  };
  const LoadLocationName = (rowData) => {
    return rowData.LoadLocationName;
  };
  const LoadLocationAddress = (rowData) => {
    return rowData.LoadLocationAddress;
  };
  const LoadLocationCity = (rowData) => {
    return rowData.LoadLocationCity;
  };
  const LoadLocationState = (rowData) => {
    return rowData.LoadLocationState;
  };
  const LoadLocationZip = (rowData) => {
    return rowData.LoadLocationZip;
  };

  const DeliveryLocationName = (rowData) => {
    return rowData.DeliveryLocationName;
  };
  const DeliveryLocationAddress = (rowData) => {
    return rowData.DeliveryLocationAddress;
  };
  const DeliveryLocationCity = (rowData) => {
    return rowData.DeliveryLocationCity;
  };
  const DeliveryLocationState = (rowData) => {
    return rowData.DeliveryLocationState;
  };
  const DeliveryLocationZip = (rowData) => {
    return rowData.DeliveryLocationZip;
  };

  const Quantity = (rowData) => {
    return <NumericFormat value={rowData.Quantity} displayType={'text'} thousandSeparator=","/>;
  };
  const UOM = (rowData) => {
    return rowData.UOM;
  };
  const Product = (rowData) => {
    return rowData.Product;
  };
  const Comments = (rowData) => {
    return rowData.Comments;
  };
  const BOLFile = (rowData) => {
    if (rowData.FileInfo) {
      return JSON.parse(rowData.FileInfo).map((item, i) => (
        <a key={i} onClick={event => GetFile(event, item.FileName, item.FileType, rowData.PONumber, item.FileStatus)} href="#" target="_blank" rel="noopener,noreferrer">
          {item.FileType.toLowerCase() === "bol" ? item.FileName : ""}{" "}
        </a>
      ));
    }
  };

  const WayBillFile = (rowData) => {
    if (rowData.FileInfo) {
      return JSON.parse(rowData.FileInfo).map((item, i) => (
        <a key={i} onClick={event => GetFile(event, item.FileName, item.FileType, rowData.PONumber, item.FileStatus)} href="#" target="_blank" rel="noopener,noreferrer">
          {item.FileType.toLowerCase() === "waybill" ? item.FileName : ""}{" "}
        </a>
      ));
    }
  };

  const InvoiceFile = (rowData) => {
    if (rowData.FileInfo) {
      return JSON.parse(rowData.FileInfo).map((item, i) => (
        <a key={i} onClick={event => GetFile(event, item.FileName, item.FileType, rowData.PONumber, item.FileStatus)} href="#" target="_blank" rel="noopener,noreferrer">
          {item.FileType.toLowerCase() === "invoice" ? item.FileName : ""}{" "}
        </a>
      ));
    }
  };

  const AddDocs = (rowData) => {
    if (rowData.FileInfo) {
      return JSON.parse(rowData.FileInfo).map((item, i) => (
        <a key={i} onClick={event => GetFile(event, item.FileName, item.FileType, rowData.PONumber, item.FileStatus)} href="#" target="_blank" rel="noopener,noreferrer">
          {item.FileType.toLowerCase() === "docs" ? item.FileName : ""}{" "}
        </a>
      ));
    }
  };

  const LoadStatus = (rowData) => {
    if (rowData.LoadStatus === "In Process") return <label style={{ color: "blue" }}>In Process</label>;
    if (rowData.LoadStatus === "Edited") return <label style={{ color: "orange" }}>Edited</label>;
    if (rowData.LoadStatus === "Complete") return <label style={{ color: "green" }}>Complete</label>;
    if (rowData.LoadStatus === "Change Approved") return <label style={{ color: "green" }}>Change Approved</label>;
    if (rowData.LoadStatus === "Declined") return <label style={{ color: "red" }}>Declined</label>;
    if (rowData.LoadStatus === "Not Started") return <label>Not Started</label>;
  };

  const InvoiceStatus = (rowData) => {
    if (rowData.InvoiceStatus === "In Process") return <label style={{ color: "blue" }}>In Process</label>;
    if (rowData.InvoiceStatus === "Submitted") return <label style={{ color: "green" }}>Submitted</label>;
    if (rowData.InvoiceStatus === "Declined") return <label style={{ color: "red" }}>Declined</label>;
    if (rowData.InvoiceStatus === "Not Started") return <label>Not Started</label>;
  };

  const handleLoadLocationColumn = (e) => {
    if (e.value) {
      setShowLoadLoc(null);
    } else {
      setShowLoadLoc("none");
    }
    //setLoadLoc(e.value);
    dispatch('LOADLOC', e.value);
  };

  const handleDeliveryLocationColumn = (e) => {
    if (e.value) {
      setShowDeliverLoc(null);
    } else {
      setShowDeliverLoc("none");
    }
    //setDeliverLoc(e.value);
    dispatch('DELIVERLOC', e.value);
  };

  const handleDocumentsColumn = (e) => {
    if (e.value) {
      setShowDoc(null);
    } else {
      setShowDoc("none");
    }
    setDoc(e.value);
  };

  let headerGroup = (
    <ColumnGroup>
      <Row>
        <Column header="Carrier Details" colSpan={9} className="center-aligned-columns"/>
        <Column header="Load Location" colSpan={5} style={{ display: state.loadloc ? null : 'none' }}  className="center-aligned-columns"/>
        <Column header="Delivery Location" colSpan={5} style={{ display: state.deliverloc ? null : 'none' }} className="center-aligned-columns"/>
        <Column header="Load Details" colSpan={3} className="center-aligned-columns"/>
        <Column header="Documents" colSpan={4} style={{ display: ShowDoc }} className="center-aligned-columns"/>
        <Column header="Status" colSpan={3} className="center-aligned-columns"/>
      </Row>
      <Row>
        <Column header="Load Confirmation Status" sortable field="LoadConfirmationStatus" style={{ width: "100px" }} />
        <Column header="PO#" field="PONumber" sortable style={{ width: "100" }}/>
        <Column header="TMW#" sortable field="TMWNumber" style={{ width: "100" }}/>
        <Column header="Carrier"  field="CarrierName" style={{ width: "150px" }}/>
        <Column header="BOL#" sortable field="BOL" style={{ width: "100px" }}/>
        <Column header="Requested Load Date" field="RequestedLoadDate" sortable style={{ width: "100px" }}/>
        <Column header="Actual Load Date" field="ActualLoadDate" sortable style={{ width: "100px" }}/>
        <Column header="Requested Delivery Date" field="RequestedDeliveryDate" sortable style={{ width: "100px" }}/>
        <Column header="Actual Delivery Date" field="ActualDeliveryDate" sortable style={{ width: "100px" }}/>

        <Column header="Name" style={{ display: state.loadloc ? null : 'none', width: "150px" }} />
        <Column header="Address" style={{ display: state.loadloc ? null : 'none', width: "150px" }} />
        <Column header="City" style={{ display: state.loadloc ? null : 'none', width: "150px" }} />
        <Column header="State" style={{ display: state.loadloc ? null : 'none', width: "150px" }} />
        <Column header="Zip" style={{ display: state.loadloc ? null : 'none', width: "150px" }} />

        <Column header="Name" style={{ display: state.deliverloc ? null : 'none', width: "150px" }} />
        <Column header="Address" style={{ display: state.deliverloc ? null : 'none', width: "150px" }} />
        <Column header="City" style={{ display: state.deliverloc ? null : 'none', width: "150px" }} />
        <Column header="State" style={{ display: state.deliverloc ? null : 'none', width: "150px" }} />
        <Column header="Zip" style={{ display: state.deliverloc ? null : 'none', width: "150px" }} />

        <Column header="Quantity" field="Quantity"  sortable className="right-align-column" style={{ width: "100px" }}/>
        <Column header="UOM" style={{ width: "100px" }}/>
        <Column header="Product" style={{ width: "100px" }}/>

        <Column header="BOL" style={{ display: ShowDoc, width: "150px" }} />
        <Column header="WayBill" style={{ display: ShowDoc, width: "150px" }} />
        <Column header="Invoice" style={{ display: ShowDoc, width: "150px" }} />
        <Column header="Add. Doc" style={{ display: ShowDoc, width: "150px" }} />

        <Column header="Comments" style={{ width: "150px" }}/>
        <Column header="Load Status" field="LoadStatus" sortable style={{ width: "100px" }}/>
        <Column header="Invoice Status" field="InvoiceStatus" sortable style={{ width: "100px" }}/>
      </Row>
    </ColumnGroup>
  );

  return (
    <React.Fragment>
    <Toast ref={myToast} />
      <div className="p-ai-center expand-collapse-buttons rail-hub-expand-columns p-d-flex p-flex-nowrap p-jc-end">
        <span className="p-mr-2">Expand/Collapse:</span>
        <ToggleButton className="p-button-sm p-mr-2" onLabel="Load Location" offLabel="Load Location" onIcon="pi pi-check" offIcon="pi pi-plus" checked={state.loadloc} onChange={handleLoadLocationColumn} />
        <ToggleButton
          className="p-button-sm p-mr-2"
          onLabel="Delivery Location"
          offLabel="Delivery Location"
          onIcon="pi pi-check"
          offIcon="pi pi-plus"
          checked={state.deliverloc}
          onChange={handleDeliveryLocationColumn}
        />

        <ToggleButton className="p-button-sm p-mr-2" onLabel="Documents" offLabel="Documents" onIcon="pi pi-check" offIcon="pi pi-plus" checked={Doc} onChange={handleDocumentsColumn} />
      </div>

      <div className="table-container table-responsive custom-no-display-table">
        <DataTable
          id="id"
          className="p-datatable-sm"
          value={renderDetails(tabledata)}
          paginator
          headerColumnGroup={headerGroup}
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={20}
          rowsPerPageOptions={[10, 20, 50]}
          removableSort
          stripedRows
          responsiveLayout="scroll"
          scrollable
          sortable
          scrollHeight="350px"
          scrollDirection="horizontal"
          showGridlines
        >
          <Column field="LoadConfirmationStatus" body={LoadConfirmationStatus} style={{ width:"100px" }} />
          <Column field="PO#" body={PONumber} style={{ width: "100px"}}/>
          <Column field="TMWNumber#" body={TMWNumber} style={{ width: "100px"}}/>
          <Column field="CarrierName" body={CarrierName} style={{ width: "150px"}} className="p-text-capitalize"/>
          <Column field="BOL" body={BOL} style={{ width: "100px" }}/>
          <Column field="RequestedLoadDate"  body={RequestedLoadDate} style={{ width: "100px" }}/>
          <Column field="ActualLoadDate" body={ActualLoadDate} style={{ width: "100px" }}/>
          <Column field="RequestedDeliveryDate" body={RequestedDeliveryDate} style={{ width: "100px" }}/>
          <Column field="ActualDeliveryDate" body={ActualDeliveryDate} style={{ width: "100px" }} />

          <Column field="LoadLocationName" body={LoadLocationName} style={{ display: state.loadloc ? null : 'none', width: "150px" }} />
          <Column field="LoadLocationAddress" body={LoadLocationAddress} style={{ display: state.loadloc ? null : 'none', width: "150px" }} />
          <Column field="LoadLocationCity" body={LoadLocationCity} style={{ display: state.loadloc ? null : 'none', width: "150px" }} />
          <Column field="LoadLocationState" body={LoadLocationState} style={{ display: state.loadloc ? null : 'none', width: "150px" }} />
          <Column field="LoadLocationZip" body={LoadLocationZip} style={{ display: state.loadloc ? null : 'none', width: "150px" }} />

          <Column field="DeliveryLocationName" body={DeliveryLocationName} style={{ display: state.deliverloc ? null : 'none', width: "150px" }} />
          <Column field="DeliveryLocationAddress" body={DeliveryLocationAddress} style={{ display: state.deliverloc ? null : 'none', width: "150px" }} />
          <Column field="DeliveryLocationCity" body={DeliveryLocationCity} style={{ display: state.deliverloc ? null : 'none', width: "150px" }} />
          <Column field="DeliveryLocationState" body={DeliveryLocationState} style={{ display: state.deliverloc ? null : 'none', width: "150px" }} />
          <Column field="DeliveryLocationZip" body={DeliveryLocationZip} style={{ display: state.deliverloc ? null : 'none', width: "150px" }} />

          <Column field="Quantity" body={Quantity} className="right-align-column" style={{ width: "100px" }}/>
          <Column field="UOM" body={UOM} style={{ width: "100px" }}/>
          <Column field="Product" body={Product} style={{ width: "100px" }}/>

          <Column field="BOLFiles" body={BOLFile} style={{ display: ShowDoc, width: "150px" }} />
          <Column field="WayBillFile" body={WayBillFile} style={{ display: ShowDoc, width: "150px" }} />
          <Column field="InvoiceFile" body={InvoiceFile} style={{ display: ShowDoc, width: "150px" }} />
          <Column field="AddDoc" body={AddDocs} style={{ display: ShowDoc, width: "150px" }} />

          <Column field="Comments" body={Comments} style={{ width: "150px" }}/>
          <Column field="LoadStatus" body={LoadStatus} style={{ width: "100px" }}/>
          <Column field="InvoiceStatus" body={InvoiceStatus} style={{ width: "100px" }}/>
        </DataTable>
      </div>
    </React.Fragment>
  );
};

export default CarrierData;
