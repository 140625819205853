import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/amerigas_logo.svg";
import { useStore } from "../hooks-store/store";

const Nav = (props) => {
  const [state] = useStore();

  return (
    <>
      <div>
        {process.env.REACT_APP_ENV !== "prod" ? <div className="current-env-mode">{process.env.REACT_APP_ENV} environment</div> : null}
        <nav className={`navbar navbar-expand-lg navbar-light bg-light fixed-top ${process.env.REACT_APP_ENV !== "prod" ? "devmode" : ""}`}>
          <div className="container-fluid">
            <span className="navbar-brand">
              <Link className="nav-link" to="/">
                <img className="amerigas-logo" src={Logo} alt="AmeriGas Logo" />
              </Link>
            </span>
            <React.Fragment>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
              <div className="collapse navbar-collapse" id="navbarNavDropdown"></div>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <div className="logo-divi"></div>
                        Logged-in User:&nbsp;<b style={{ color: "#8a97a7" }}>{props.user}&nbsp;</b>
                      </div>
                    </td>
                    {(state.isinternal === "Y") || (state.isinternal === "N" && state.carrier === process.env.REACT_APP_DeliveredLoadCarrierName
                    && state.isactive === "Y")  ? null :
                    
                    <td>
                      <div>
                        <div className="logo-divi"></div>
                        <b>|&nbsp;</b>
                        Carrier:&nbsp;
                        <b style={{ color: "#8a97a7" }}>{props.carrier}&nbsp;</b>
                      </div>
                    </td>
                    }
                    <td>
                      <div>
                        <div className="logo-divi">{props.sbutton}</div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div>
                        <div className="logo-divi"></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </React.Fragment>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Nav;
