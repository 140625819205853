import React from "react";

const Error = () => {


    return (
        <div className="no-access-container">
            <div className="no-access-inner-container">
                <i className="pi pi-exclamation-triangle no-access-icon"></i>
                <h3>Sorry, Some error occured, Please contact support team.</h3>
            </div>
        </div>
    )
};

export default Error;