import { initStore } from "./store";

const configureStore = () => {
    const actions = {
        PONUM: (state, serverResp) => ({ ponum: serverResp }),
        TMWNUMBER: (state, serverResp) => ({ tmwnum: serverResp }),
        LIMIT: (state, serverResp) => ({ limit: serverResp }),
        MEASUREDIN: (state, serverResp) => ({ measuredin: serverResp }),
        ACTIVELINK: (state, serverResp) => ({ activelink: serverResp }),
        APPLYFILTERS: (state, applyFilter) => ({ applyFilters: applyFilter }),
    };

    initStore(actions, {
        ponum: null,
        tmwnum: null,
        limit: null,
        measuredin: null,
        activelink: "/",
        applyFilters: false
    });
};

export default configureStore;
