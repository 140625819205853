import React from "react";
import { useStore } from "../hooks-store/store";

const NoAccess = () => {
    const [state, dispatch] = useStore();

    return (
        <div className="no-access-container">
            <div className="no-access-inner-container">
                <i className="pi pi-exclamation-triangle no-access-icon"></i>
                <h3>We are sorry {state.username}, you do not have access to this application.</h3>
            </div>
        </div>
    )
};

export default NoAccess;