import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { confirmDialog } from "primereact/confirmdialog";
import { Panel } from "primereact/panel";
import { useStore } from "../../hooks-store/store";
import { MultiSelect } from "primereact/multiselect";
import { ProductService } from "../../service/ProductService";
import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import moment from "moment";

const Filters = (props) => {
  let startDate = null;
  let endDate = null;
  const dateRangeRef = useRef();
  const poNumberRef = useRef();
  const TMWNumberRef = useRef();
  const BOLNumberRef = useRef();
  const railNumberRef = useRef();
  const ConfirmationRef = useRef();
  const LoadRef = useRef();
  const InvoiceRef = useRef();
  const selectedDateTypeRef = useRef();
  const [state, dispatch] = useStore();
  const [visibleRight, setVisibleRight] = useState(false);
  const [panelCollapsed, setPanelCollapsed] = useState(null);

  const [ConfirmationOptions, setConfirmationOptions] = useState([]);
  const [LoadOptions, setLoadOptions] = useState([]);
  const [InvoiceOptions, setInvoiceOptions] = useState([]);
  const [formInputsValidity, setFormInputsValidity] = useState({
    dateRange: true,
    dateType: true,
  });

  const productService = new ProductService();

  useEffect(() => {
    let mounted = true;
    productService.CarrierLoadStatusFilters().then((data) => {
      if (mounted) {
        setConfirmationOptions(
          data[0].ConfirmationStatus.map((item) => ({
            name: item.Status,
            key: item.Key,
          }))
        );
        setLoadOptions(
          data[0].LoadStatus.map((item) => ({
            name: item.Status,
            key: item.Key,
          }))
        );
        setInvoiceOptions(
          data[0].InvoiceStatus.map((item) => ({
            name: item.Status,
            key: item.Key,
          }))
        );
      }
      return () => (mounted = false);
    });
  }, []);

  const selectTemplate = (option) => {
    return (
      <div className="select-item">
        <div>{option.name}</div>
      </div>
    );
  };
  const selectedCarrierTemplate = (option) => {
    if (option) {
      return (
        <div className="region-item item-value">
          <div>{option.name}</div>
        </div>
      );
    }
    return (
      <div className="hidden-attr" style={{ color: "#fff" }}>
        hidden
      </div>
    );
  };

  const handleSelectedConfirmationStatus = (e) => {
    dispatch('CNFRMSTATUS',e.target.value);
  };

  const handleLoadStatus = (e) => {
    dispatch('LOADSTATUS',e.target.value);
  };

  const handleInvoiceStatus = (e) => {
    dispatch('INVSTATUS',e.target.value);
  };
  
  const search = (e) => {
    e.preventDefault();
    
    if (state.filterdates !== null && state.filterdates[0] !== null && state.filterdates[1] !== null)
    {
      startDate = moment(new Date(state.filterdates[0]).toString()).format("YYYYMMDD");
      endDate = moment(new Date(state.filterdates[1]).toString()).format("YYYYMMDD");
      dispatch("FILTERSTARTDATE", startDate);
      dispatch("FILTERENDDATE", endDate);
      
      if ((endDate-startDate) >=process.env.REACT_APP_DAYS_FOR_CONFIRMATION) {
        confirmDialog({
          message: 'You are pulling a large set of data, It might take few minutes to Load. Do you want to proceed?',
          header: 'Confirmation',
          icon: 'pi pi-exclamation-triangle',
          accept: () => dispatch("SEARCHCP", "search"),
        });
      }
      else{
        dispatch("SEARCHCP", "search");
      }
    }
    productService.Log_User_Action({
      "User":state.email,
      "Application":"CP",
      "Module":"Load Information",
      "Action":"Load Search",
      "ActionDesc":"Searched between "+startDate+" And "+endDate,
      "AccessDatetime": new Date()
    }).then((response) => {
      if (response !== "User action saved." || response === null)
      {
        alert("Log Error!\n\nPlease report this error to the IT team and continue with your task. It will not be affected.\nThanks!");
      
      }
    })
  };

  return (
    <>
      <Panel header="Search" toggleable collapsed={panelCollapsed} onToggle={(e) => setPanelCollapsed(e.value)} className="rail-hub-filters">
        <div className="p-grid">
          <div className="col p-field">
            <form>
              <div className="p-grid p-fluid">
                <div className="p-field col m-2">
                  <span className="p-float-label">
                      <Calendar
                          id="range"
                          value={state.filterdates}
                          onChange={(e) => {dispatch('FILTERDATES',e.value)}}
                          selectionMode="range"
                          selectOtherMonths="true"
                          className={!formInputsValidity.dateRange ? "p-invalid" : ""}
                      />
                      <label>Date Range<span className="req">*</span></label>
                  </span>
              </div>
                <div className="p-field col m-2">
                  <span className="p-float-label">
                    <InputText value={state.filterpo} onChange={(e) => dispatch('FILTERPO',e.target.value)} ref={poNumberRef} />
                    <label>PO Number</label>
                  </span>
                </div>

                <div className="p-field col m-2">
                  <span className="p-float-label">
                    <InputText value={state.filtertmw} onChange={(e) => dispatch('FILTERTMW',e.target.value)} ref={TMWNumberRef} />
                    <label>TMW Number</label>
                  </span>
                </div>

                <div className="p-field col m-2">
                  <span className="p-float-label">
                    <InputText value={state.filterbol} onChange={(e) => dispatch('FILTERBOL',e.target.value)} ref={BOLNumberRef} />
                    <label>BOL Number</label>
                  </span>
                </div>

                <div className="p-field col m-2">
                  <span className="p-float-label">
                    <MultiSelect
                      filter
                      className="multiselect-custom"
                      value={state.cnfrmstatus}
                      options={ConfirmationOptions}
                      onChange={handleSelectedConfirmationStatus}
                      optionLabel="name"
                      itemTemplate={selectTemplate}
                      selectedItemTemplate={selectedCarrierTemplate}
                      showClear
                      ref={ConfirmationRef}
                    />
                    <label htmlFor="CarrierID">Confirmation Status</label>
                  </span>
                </div>

                <div className="p-field col m-1 p-1 pr-2">
                  <span className="p-float-label">
                    <MultiSelect
                      filter
                      className="multiselect-custom"
                      value={state.loadstatus}
                      options={LoadOptions}
                      onChange={handleLoadStatus}
                      optionLabel="name"
                      itemTemplate={selectTemplate}
                      selectedItemTemplate={selectedCarrierTemplate}
                      showClear
                      ref={LoadRef}
                    />
                    <label htmlFor="LoadStatus">Load Status</label>
                  </span>
                </div>

                <div className="p-field col m-1 p-1 pl-2">
                  <span className="p-float-label">
                    <MultiSelect
                      filter
                      className="multiselect-custom"
                      value={state.invstatus}
                      options={InvoiceOptions}
                      onChange={handleInvoiceStatus}
                      optionLabel="name"
                      itemTemplate={selectTemplate}
                      selectedItemTemplate={selectedCarrierTemplate}
                      showClear
                      ref={InvoiceRef}
                    />
                    <label htmlFor="SupplierID">Invoice Status</label>
                  </span>
                </div>
              </div>
              <div className="p-grid p-field p-fluid justify-content-end ">
                <div className="p-field col-1 justify-content-end pr-2">
                  <Button label="Search" onClick={search} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </Panel>

      {}
    </>
  );
};

export default Filters;
