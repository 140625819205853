import { initStore } from "./store";

const configureStore = () => {
    const actions = {
        USERNAME: (state, serverResp) => ({ username: serverResp }),
        FULLNAME: (state, serverResp) => ({ fullname: serverResp }),
        EMAIL: (state, serverResp) => ({ email: serverResp }),
        ISINTERNAL: (state, serverResp) => ({ isinternal: serverResp }),
        ISLOADING: (state, serverResp) => ({ isloading: serverResp }),
        ISACTIVE: (state, serverResp) => ({ isactive: serverResp }),
        FILTERPO: (state, serverResp) => ({ filterpo: serverResp }),
        FILTERTMW: (state, serverResp) => ({ filtertmw: serverResp }),
        FILTERBOL: (state, serverResp) => ({ filterbol: serverResp }),
        CNFRMSTATUS: (state, serverResp) => ({ cnfrmstatus: serverResp }),
        LOADSTATUS: (state, serverResp) => ({ loadstatus: serverResp }),
        INVSTATUS: (state, serverResp) => ({ invstatus: serverResp }),
        FILTERSTARTDATE: (state, serverResp) => ({ filterstartdate: serverResp }),
        FILTERENDDATE: (state, serverResp) => ({ filterenddate: serverResp }),
        FILTERDATES: (state, serverResp) => ({ filterdates: serverResp }),
        CARRIER: (state, serverResp) => ({ carrier: serverResp })
    };

    initStore(actions, {
        email: null,
        fullname: null,
        username: null,
        isinternal: null,
        isloading: false,
        isactive: null,
        filterpo: "",
        filtertmw: "",
        filterbol: "",
        cnfrmstatus: null,
        loadstatus: null,
        invstatus: null,
        filterstartdate: null,
        filterenddate: null,
        filterdates: null,
        carrier:null
    });
};

export default configureStore;
