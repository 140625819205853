import React from "react";
import { useStore } from "../hooks-store/store";

const Maintenance = () => {
    const [state, dispatch] = useStore();

    return (
        <div className="no-access-container">
            <div className="no-access-inner-container">
                <i className="pi pi-exclamation-triangle no-access-icon"></i>
                <h3>This app is currently under maintenance. Contact IT team for more details.</h3>
            </div>
        </div>
    )
};

export default Maintenance;