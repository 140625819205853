import axios from "axios";
import { apiConfig } from "../apiConfig";
import { publicClientApplication } from "../index";
import moment from "moment";

export class ProductService {
  async getAccessToken() {
    const accessTokenRequest = {
      scopes: [apiConfig],
      account: publicClientApplication.getAllAccounts()[0],
    };

    await publicClientApplication.acquireTokenSilent(accessTokenRequest).then((accessTokenResponse) => {
      //Acquire token silent success
      let accessToken = accessTokenResponse.accessToken;
      axios.defaults.headers.common = { Authorization: `Bearer ${accessToken}` };
      axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
      axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*';
      axios.defaults.withCredentials = false;
    });
  }

  async getTankReadings() {
    await this.getAccessToken();
    return axios.get(`${process.env.REACT_APP_API_URL}/api/Get_Report_Tank_Readings?code=${process.env.REACT_APP_CODE}`).then((res) => res.data);
  }

  async getUserInfo(username) {
    await this.getAccessToken();
    return axios.get(`${process.env.REACT_APP_API_URL}/api/report/GetUserInfo?username=${username}`).then((res) => res.data);
  }

  async GetUserSpecificCarrierData(username, fromdate, todate, poNumber, tmwNumber, bolNumber, confirmationStatus, LoadStatus, InvoiceStatus) {
    await this.getAccessToken();
    var data = confirmationStatus;
    let sdate = fromdate === null ? moment(new Date()).subtract(`${process.env.REACT_APP_REPORT_BACK_DAYS}`, 'days').format("YYYY-MM-DD") : fromdate;
    let edate = todate === null ? moment(new Date()).add(`${process.env.REACT_APP_REPORT_FORWARD_DAYS}`, 'days').format("YYYY-MM-DD") : todate;
    if (confirmationStatus) {
      var CStatus = data
        .map(function (val) {
          return val.name;
        })
        .join(",");
    } else {
      CStatus = "";
    }

    data = LoadStatus;
    if (LoadStatus) {
      var LStatus = data
        .map(function (val) {
          return val.name;
        })
        .join(",");
    } else {
      LStatus = "";
    }

    data = InvoiceStatus;
    if (InvoiceStatus) {
      var IStatus = data
        .map(function (val) {
          return val.name;
        })
        .join(",");
    } else {
      IStatus = "";
    }
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/report/GetuserspecificCarrierDetails?username=` +
          username +
          "&fromdate=" +
          sdate +
          "&todate=" +
          edate +
          "&PONumber=" +
          poNumber +
          "&TMWNumber=" +
          tmwNumber +
          "&BOLNumber=" +
          bolNumber +
          "&CStatus=" +
          CStatus +
          "&LStatus=" +
          LStatus +
          "&IStatus=" +
          IStatus
        // , {
        //     headers: {
        //       'Authorization': 'Bearer ' +accessToken,
        //       'Content-Type': 'application/x-www-form-urlencoded'
        //     }
        //   }
      )
      .then(async (res) => {
        return await res.data["CarrierInfo"];
      });

    //});
  }

  async GetBlobFile(fileName, fileType, poNumber) {
    await this.getAccessToken();
    return (
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/userdata/GetBlobFile?filename=${fileName}&fileType=${fileType}&ponumber=${poNumber}`,
          {responseType: "blob"}
        )
        .then(async (res) => {
          return await res;
        })
    );
  }

  async GetReportData(fromdate, todate, poNumber, tmwNumber, bolNumber, confirmationStatus, LoadStatus, InvoiceStatus, DispatchArea, Region, State, Carrier) {
    await this.getAccessToken();
    var data = confirmationStatus;
    let sdate = fromdate === null ? moment(new Date()).subtract(`${process.env.REACT_APP_REPORT_BACK_DAYS}`, 'days').format("YYYY-MM-DD") : fromdate;
    let edate = todate === null ? moment(new Date()).add(`${process.env.REACT_APP_REPORT_FORWARD_DAYS}`, 'days').format("YYYY-MM-DD") : todate;
    if (confirmationStatus) {
      var CStatus = data
        .map(function (val) {
          return val.key;
        })
        .join(",");
    } else {
      CStatus = "";
    }
   
    data = LoadStatus;
    if (LoadStatus) {
      var LStatus = data
        .map(function (val) {
          return val.key;
        })
        .join(",");
    } else {
      LStatus = "";
    }

    data = InvoiceStatus;
    if (InvoiceStatus) {
      var IStatus = data
        .map(function (val) {
          return val.key;
        })
        .join(",");
    } else {
      IStatus = "";
    }
    data = DispatchArea;
    if (DispatchArea) {
      var DA = data
        .map(function (val) {
          return val.code;
        })
        .join(",");
    } else {
      DA = "";
    }
    data = Region;
    if (Region) {
      var region = data
        .map(function (val) {
          return val.name;
        })
        .join(",");
    } else {
      region = "";
    }
    data = State;
    if (State) {
      var state = data
        .map(function (val) {
          return val.abbreviation;
        })
        .join(",");
    } else {
      state = "";
    }
    data = Carrier;
    if (Carrier) {
      var carrier = data
        .map(function (val) {
          return val.name;
        })
        .join(",");
    } else {
      carrier = "";
    }
    return axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/report/GetCarrierReportData?FromDate=` +
          sdate +
          "&ToDate=" +
          edate +
          "&PONumber=" +
          poNumber +
          "&TMWNumber=" +
          tmwNumber +
          "&BOLNumber=" +
          bolNumber +
          "&CStatus=" +
          CStatus +
          "&LStatus=" +
          LStatus +
          "&IStatus=" +
          IStatus +
          "&DispatchArea=" +
          DA +
          "&Region=" +
          region +
          "&State=" +
          state +
          "&Carrier=" +
          carrier
        // , {
        //     headers: {
        //       'Authorization': 'Bearer ' +accessToken,
        //       'Content-Type': 'application/x-www-form-urlencoded'
        //     }
        //   }
      )
      .then(async (res) => {
        return await res.data["CarrierInfo"];
      });

    //});
  }

  async CarrierLoadReportFilters() {
    await this.getAccessToken();
    return axios.get(`${process.env.REACT_APP_API_URL}/api/report/Getreportfilters`).then(async (res) => {
      return await res.data["CarrierInfo"];
    });
  }

  async CarrierLoadStatusFilters() {
    await this.getAccessToken();
    return axios.get(`${process.env.REACT_APP_API_URL}/api/report/GetStatusfilters`).then(async (res) => {
      return await res.data["CarrierInfo"];
    });
  }

  async DARegionFilters() {
    await this.getAccessToken();
    return axios.get(`${process.env.REACT_APP_API_URL}/api/report/Getregionfilters`).then(async (res) => {
      return await res.data["CarrierInfo"];
    });
  }

  async CarrierDateTypeFilters() {
    await this.getAccessToken();
    return axios.get(`${process.env.REACT_APP_API_URL}/api/report/GetDateTypefilters`).then(async (res) => {
      return await res.data["CarrierInfo"];
    });
  }

  async postRailBOLsCRUD(json) {
    await this.getAccessToken();
    const postData = {
      RailOrder: json,
    };
    return axios
      .post(`${process.env.REACT_APP_API_URL}/api/Post_RailOrderBOLs_CRUD?code=${process.env.REACT_APP_CODE}`, JSON.stringify(postData))
      .then(function (response) {
        return response;
      })
      .catch(function (error) {
        return error;
      });
  }

  async postFileData(data) {
    await this.getAccessToken();
    //const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return (
      axios
        //.post(`${process.env.REACT_APP_API_URL}/api/Process_CP_UploadFile?code=${process.env.REACT_APP_CODE}`, data)
        .post(`${process.env.REACT_APP_API_URL}/Api/Userdata/PostCarrierData`, data)
        .then((res) => {
          return res.status;
        })
    );
  }

  async postCompleteLoadData(data) {
    await this.getAccessToken();
    //const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return (
      axios
        //.post(`${process.env.REACT_APP_API_URL}/api/Process_CP_UploadFile?code=${process.env.REACT_APP_CODE}`, data)
        .post(`${process.env.REACT_APP_API_URL}/Api/Userdata/SubmitAndCompleteLoadData`, data)
        .then((res) => {
          return res.status;
        })
    );
  }

  async postSubmitDocs(data) {
    await this.getAccessToken();
    //const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return (
      axios
        //.post(`${process.env.REACT_APP_API_URL}/api/Process_CP_UploadFile?code=${process.env.REACT_APP_CODE}`, data)
        .post(`${process.env.REACT_APP_API_URL}/Api/Userdata/SubmitDocuments`, data)
        .then((res) => {
          return res.status;
        })
    );
  }

  async approveEditedLoad(data,tmwnumber, username) {
    await this.getAccessToken();
    //const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    return (
      axios
        //.post(`${process.env.REACT_APP_API_URL}/api/Process_CP_UploadFile?code=${process.env.REACT_APP_CODE}`, data)
        .post(`${process.env.REACT_APP_API_URL}/api/userdata/ApproveEditedLoad?ponumber=${data}&tmwnumber=${tmwnumber}&username=${username}`)
        .then((res) => {
          return res.data;
        })
    );
  }

  async getCarrierDetails(poNum,tmwnum, username, startdate, enddate) {
    let sdate = startdate === null ? moment(new Date()).subtract(`${process.env.REACT_APP_REPORT_BACK_DAYS}`, 'days').format("YYYY-MM-DD") : startdate;
    let edate = enddate === null ? moment(new Date()).add(`${process.env.REACT_APP_REPORT_FORWARD_DAYS}`, 'days').format("YYYY-MM-DD") : enddate;
    await this.getAccessToken();
    return (
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/report/GetuserspecificCarrierDetails?username=` +
            username +
            "&fromdate=" +
            sdate +
            "&todate=" +
            edate +
            "&PONumber=" +
            poNum +
            "&TMWNumber=" +
            tmwnum +
            "&BOLNumber=" +
            "" +
            "&CStatus=" +
            "" +
            "&LStatus=" +
            "" +
            "&IStatus=" +
            ""
        )
        .then(async (res) => {
          return await res.data["CarrierInfo"];
        })
    );
  }

  async GetShipperInformation() {
    await this.getAccessToken();
    return axios.get(`${process.env.REACT_APP_API_URL}/api/report/GetShipperInformation`).then(async (res) => {
      return await res.data["ShipperInformation"];
    });
  }

  async postDeliveredLoads(data) {
    await this.getAccessToken();
    return (
      axios
        .post(`${process.env.REACT_APP_API_URL}/Api/Userdata/PostDeliveredLoads`, data)
        .then((res) => {
          return res.status;
        })
    );
  }

  async Log_User_Action(data) {
    
    return axios
      .post(
        `${process.env.REACT_APP_LOGGING_API_URL}/api/LogUserAction?code=${process.env.REACT_APP_LOGGING_CODE}`,
        data
      )
      .then((res) => res.data);
  }

  async exportCPUserStatistics(startDate, endDate) {
        
    let apiString = `${process.env.REACT_APP_API_URL}/api/report/GetCPUserStats`;
    
        
    return axios
    .get(apiString, {
        responseType: 'arraybuffer',
        headers: {
        'Content-Type': 'application/json'
    }})
    .then((response) => { const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'CPUser_Statistics.xlsx'
        link.click()
    })
  }

  async exportCPUserEfficiencyRep(startDate, endDate) {
          
    let apiString = `${process.env.REACT_APP_API_URL}/api/report/GetCPUserEfficiencyRep`;
    return axios
    .get(apiString, {
        responseType: 'arraybuffer',
        headers: {
        'Content-Type': 'application/json'
    }})
    .then((response) => { const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = 'CPUser_Efficiency_Report.xlsx'
        link.click()
    })
  }

  async fetchMaintenancePageSetting() {
    await this.getAccessToken();
    return (
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/report/Fetch_MaintenancePage_Setting`)
        .then((res) => {
          return res;
        })
    );
  }

}
