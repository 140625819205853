import React, { useState, useRef, useEffect } from "react";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import { useStore, dispatch } from "../../hooks-store/store";
import { MultiSelect } from "primereact/multiselect";
import { ProductService } from "../../service/ProductService";
import { InputText } from "primereact/inputtext";
import { Calendar } from 'primereact/calendar';
import moment from "moment";
import states from "./us-states.json";
import { Toast } from "primereact/toast";

const Filters = (props) => {
  let startDate = null;
  let endDate = null;
  
  const dispatch = useStore()[1];
  const [dates, setDates] = useState("");
  const [panelCollapsed, setPanelCollapsed] = useState(null);

  const [carrierOptions, setCarrierOptions] = useState([]);
  const [ConfirmationOptions, setConfirmationOptions] = useState([]);
  const [LoadOptions, setLoadOptions] = useState([]);
  const [InvoiceOptions, setInvoiceOptions] = useState([]);
  const [DAOptions, setDAOptions] = useState([]);
  const [RegionOptions, setRegionOptions] = useState([]);
  const [formInputsValidity, setFormInputsValidity] = useState({
    dateRange: true,
    dateType: true,
  });

  const productService = new ProductService();
  const myToast = useRef(null);

  useEffect(() => {
    dispatch("ISLOADING", true);
    let mounted = true;
    productService.DARegionFilters().then((data) => {
      if (mounted) {
        setDAOptions(
          data[0].DispatchArea.map((item) => ({
            name: item.Name,
            code: item.Code,
          }))
        );

        setRegionOptions(
          data[0].Region.map((item) => ({
            name: item.Name,
            code: item.Code,
          }))
        );
      }
    });

    productService.CarrierLoadReportFilters().then((data) => {
      if (mounted) {
        setCarrierOptions(
          data.map((item) => ({
            name: item.Name,
            code: item.Id,
          }))
        );
      }
    });

    productService.CarrierLoadStatusFilters().then((data) => {
      if (mounted) {
        setConfirmationOptions(
          data[0].ConfirmationStatus.map((item) => ({
            name: item.Status,
            key: item.Key,
          }))
        );
        setLoadOptions(
          data[0].LoadStatus.map((item) => ({
            name: item.Status,
            key: item.Key,
          }))
        );
        setInvoiceOptions(
          data[0].InvoiceStatus.map((item) => ({
            name: item.Status,
            key: item.Key,
          }))
        );
      }
      dispatch("ISLOADING", false);
      return () => (mounted = false);
    });
  }, []);

  const selectTemplate = (option) => {
    return (
      <div className="select-item">
        <div>{option.name}</div>
      </div>
    );
  };
  const selectedCarrierTemplate = (option) => {
    if (option) {
      return (
        <div className="region-item item-value">
          <div>{option.name}</div>
        </div>
      );
    }
    return (
      <div className="hidden-attr" style={{ color: "#fff" }}>
        hidden
      </div>
    );
  };

  const resetFilters = (e) => {
    e.preventDefault();
     setDates(null);
    props.handlePoNumber("");
    props.handleTMWNumber("");
    props.handleBOLNumber("");
    props.handleConfirmationStatusFilters(null);
    props.handleLoadStatusFilters(null);
    props.handleInvoiceStatusFilters(null);
    props.handleDAFilters(null);
    props.handleRegionFilters(null);
    props.handleByStateFilters(null);
    props.handleCarrierFilters(null);
  };

  const search = (e) => {
    e.preventDefault();
    // console.log(dates);
    if(dates === null || dates === "" || dates[0] === null || dates[1] === null)
    {
      myToast.current.show({ severity: "info", summary: "Date Range is mandatory", detail: "Please enter a date range to retrieve data", sticky: false });
    }
    else if (dates !== null && dates[0] !== null && dates[1] !== null)
    {
        startDate = moment(new Date(dates[0]).toString()).format("YYYYMMDD");
        endDate = moment(new Date(dates[1]).toString()).format("YYYYMMDD");
        props.handleStartDate(startDate);
        props.handleEndDate(endDate);
        dispatch("SEARCHCP", "search");
    }
  };

  return (
    <>
      <Toast ref={myToast} />
      <Panel header="Search" toggleable collapsed={panelCollapsed} onToggle={(e) => setPanelCollapsed(e.value)} className="rail-hub-filters">
        <div className="p-grid">
          <div className="p-col-12 ">
            <form>
              <div className="p-fluid p-formgrid p-grid">
              <div className="p-field col">
                  <span className="p-float-label">
                      <Calendar
                          id="range"
                          value={dates}
                          onChange={(e) => {setDates(e.value)}}
                          selectionMode="range"
                          selectOtherMonths="true"
                          className={!formInputsValidity.dateRange ? "p-invalid" : ""}
                      />
                      <label>Date Range<span className="req">*</span></label>
                  </span>
              </div>
                <div className="p-field col">
                  <span className="p-float-label">
                    <InputText value={props.selectedPoNumber} onChange={(e) => props.handlePoNumber(e.target.value)} />
                    <label>PO Number</label>
                  </span>
                </div>

                <div className="p-field col">
                  <span className="p-float-label">
                    <InputText value={props.selectedTMWNumber} onChange={(e) => props.handleTMWNumber(e.target.value)} />
                    <label>TMW Number</label>
                  </span>
                </div>

                <div className="p-field col">
                  <span className="p-float-label">
                    <InputText value={props.selectedBOLNumber} onChange={(e) => props.handleBOLNumber(e.target.value)} />
                    <label>BOL Number</label>
                  </span>
                </div>

                <div className="p-field col">
                  <span className="p-float-label">
                    <MultiSelect
                      filter
                      id="ConfirmationStatus"
                      className="multiselect-custom"
                      value={props.selectedConfirmationFilter}
                      options={ConfirmationOptions}
                      onChange={(e) => props.handleConfirmationStatusFilters(e.target.value)}
                      optionLabel="name"
                      itemTemplate={selectTemplate}
                      selectedItemTemplate={selectedCarrierTemplate}
                      showClear
                    />
                    <label htmlFor="ConfirmationStatus">Confirmation Status</label>
                  </span>
                </div>

                <div className="p-field col">
                  <span className="p-float-label">
                    <MultiSelect
                      filter
                      id="LoadStatus"
                      className="multiselect-custom"
                      value={props.selectedLoadFilter}
                      options={LoadOptions}
                      onChange={(e) => props.handleLoadStatusFilters(e.target.value)}
                      optionLabel="name"
                      itemTemplate={selectTemplate}
                      selectedItemTemplate={selectedCarrierTemplate}
                      showClear
                    />
                    <label htmlFor="LoadStatus">Load Status</label>
                  </span>
                </div>
                </div>
                <div className="p-grid p-fluid mt-4">
                <div className="p-field col">
                  <span className="p-float-label">
                    <MultiSelect
                      filter
                      id="InvoiceStatus"
                      className="multiselect-custom"
                      value={props.selectedInvoiceFilter}
                      options={InvoiceOptions}
                      onChange={(e) => props.handleInvoiceStatusFilters(e.target.value)}
                      optionLabel="name"
                      itemTemplate={selectTemplate}
                      selectedItemTemplate={selectedCarrierTemplate}
                      showClear
                    />
                    <label htmlFor="InvoiceStatus">Invoice Status</label>
                  </span>
                </div>

                <div className="p-field col">
                  <span className="p-float-label">
                    <MultiSelect
                      filter
                      id="DispatchAreas"
                      className="multiselect-custom"
                      value={props.selectedDAFilter}
                      options={DAOptions}
                      onChange={(e) => props.handleDAFilters(e.target.value)}
                      optionLabel="name"
                      itemTemplate={selectTemplate}
                      selectedItemTemplate={selectedCarrierTemplate}
                      showClear
                    />
                    <label htmlFor="DispatchAreas">Dispatch Areas</label>
                  </span>
                </div>

                <div className="p-field col">
                  <span className="p-float-label">
                    <MultiSelect
                      filter
                      className="multiselect-custom"
                      id="RegionID"
                      value={props.selectedRegionFilter}
                      options={RegionOptions}
                      onChange={(e) => props.handleRegionFilters(e.target.value)}
                      optionLabel="name"
                      itemTemplate={selectTemplate}
                      selectedItemTemplate={selectedCarrierTemplate}
                      showClear
                    />
                    <label htmlFor="RegionID">Region</label>
                  </span>
                </div>
                <div className="p-field col">
                  <span className="p-float-label">
                    <MultiSelect
                      filter
                      className="multiselect-custom"
                      id="StatesID"
                      value={props.selectedByState}
                      options={states}
                      onChange={(e) => props.handleByStateFilters(e.target.value)}
                      optionLabel="name"
                      itemTemplate={selectTemplate}
                      selectedItemTemplate={selectedCarrierTemplate}
                      showClear
                    />
                    <label htmlFor="StatesID">State</label>
                  </span>
                </div>
                <div className="p-field col">
                  <span className="p-float-label">
                    <MultiSelect
                      filter
                      className="multiselect-custom"
                      id="CarrierID"
                      value={props.selectedCarrierFilter}
                      options={carrierOptions}
                      onChange={(e) => props.handleCarrierFilters(e.value)}
                      optionLabel="name"
                      itemTemplate={selectTemplate}
                      selectedItemTemplate={selectedCarrierTemplate}
                      showClear
                    />
                    <label htmlFor="CarrierID">Carrier Code</label>
                  </span>
                </div>
                
                <div className="search-btn p-field col">
                  <div className="p-grid p-fluid mt-0">
                    <div className="col mr-0 pr-0">
                      <Button label="Search" onClick={search} />
                    </div>
                    <div className="col">
                      <Button style={{"backgroundColor":"#555555"}} label="Reset" onClick={resetFilters} />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Panel>

      {}
    </>
  );
};

export default Filters;
